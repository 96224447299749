import React from 'react'
import "../styles/main.css";
import Input from "./Input";

const Getintouch = (props) => {

  function popup() {
    props.setPop(false)
  }

  return (
    <>
    <div className='position-fixed getintouch-bg-1 d-flex justify-content-center align-items-center'>


    <form onSubmit={popup} className="d-flex position-relative flex-column align-items-center justify-content-center col-lg-6 py-5 px-5 getintouch-bg-2 shadow-lg">
      <div onClick={popup} className='close position-absolute'>X</div>
    <h1 className='sect-5-heading w0 colwhite mx-auto'>Get In Touch With Us.</h1>

              <div className=" d-flex justify-content-center pt-4 pt-lg-0 smalldev">
                <Input type="text" name="text" placeholder="First Name" />
                <Input type="number" name="number" placeholder="Phone" />
              </div>

              <div className=" d-flex justify-content-center smalldev">
                <Input type="email" name="email" placeholder="Email ID" />
                <Input type="text" name="text" placeholder="Subject" />
              </div>

                <div>
                    <input type="text" name="text" placeholder="Message" className="message" />
                </div>

                <button className="subheadings d-flex justify-content-center border-0  align-items-center sect-7-button mt-5">
                    Submit

                </button>
            </form>

    </div>
      
    </>
  )
}

export default Getintouch
