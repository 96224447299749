import React from "react";
import Footer from "../components/Footer";
import Navb from "../components/Navb";
import Sect1 from "../components/Sect1";
import Sect2 from "../components/Sect2";
import Sect3 from "../components/Sect3";
import Sect4 from "../components/Sect4";
import Sect5 from "../components/Sect5";
import Sect6 from "../components/Sect6";
import Sect7 from "../components/Sect7";

const Home = () => {
  return (
    <>
      <Navb />
      <Sect1 />
      <Sect2 />
      <Sect3 />
      <Sect4 />
      <Sect5 />
      <Sect6 />
      <Sect7 />
      <Footer />
    </>
  );
};

export default Home;
