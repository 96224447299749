import React from "react";
import "../fonts/Poppins/Poppins-Black.ttf";
import "../styles/main.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";

function Navb() {
  const navigate = useNavigate();
  function handleClick() {
    navigate("/");
  }

  function handleClick2() {
    navigate("/apintegdev");
  }

  function handleClick3() {
    navigate("/appdevserv");
  }

  function handleClick4() {
    navigate("/custsoftdev");
  }

  function handleClick5() {
    navigate("/mobappdev");
  }
  return (
    <>
      <Navbar sticky="top" className="bgwhite shadow" expand="lg">
        <Container>
          <Navbar.Brand href="#home">
            {/* <img
              src={require("../images/syntrinologo.png")}
              alt=""
              className="img-fluid"
            /> */}
            KPN Services
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={handleClick} href="#home">
                Home
              </Nav.Link>
              <Nav.Link href="#link">About Us</Nav.Link>
              <NavDropdown title="Services" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={handleClick2} href="#action/3.1">
                  API Integration Development
                </NavDropdown.Item>
                <NavDropdown.Item onClick={handleClick3} href="#action/3.2">
                  Application Development Services
                </NavDropdown.Item>
                <NavDropdown.Item onClick={handleClick4} href="#action/3.3">
                  Custom Software Development
                </NavDropdown.Item>
                <NavDropdown.Item onClick={handleClick5} href="#action/3.4">
                  Mobile App Development Services
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="#home">Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navb;
