import React from "react";
import { useState } from "react";
import "../styles/main.css";
import Footer from "../components/Footer";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Getintouch from "../components/Getintouch";
import Navb from "../components/Navb";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );

  return (
    <button
      className="accord-btn my-2"
      type="button"
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

const MobAppDev = () => {
  const [collapse, setCollapse] = useState(1);

  function handleClick(e) {
    if (e.target.className === "button1") {
      setCollapse(1);
    } else if (e.target.className === "button2") {
      setCollapse(2);
    } else if (e.target.className === "button3") {
      setCollapse(3);
    } else if (e.target.className === "button4") {
      setCollapse(4);
    } else if (e.target.className === "button5") {
      setCollapse(5);
    } else if (e.target.className === "button6") {
      setCollapse(6);
    } else if (e.target.className === "button7") {
      setCollapse(7);
    }
  }

  const [pop, setPop] = useState(false);

  function formPop() {
    setPop(true);
  }

  return (
    <>
      <Navb />
      {pop && <Getintouch setPop={setPop} />}
      {/* Section1 of api page */}
      <div className="container-fluid">
        <div className="col-12">
          <div className="row">
            <div className="col-lg pt-5 pb-5 ps-5 d-flex flex-column">
              <h1 className="sect-1-heading mt-5">Scalable Mobile App</h1>
              <h1 className="sect-1-heading">Development Services</h1>
              <p className="subheadings sub-1-width">
                We deliver custom mobile app development service for Android and
                iOS platforms, streamline your business operations.
              </p>
              <button
                onClick={formPop}
                className="sect-1-button d-flex justify-content-center align-items-center border-0"
              >
                Get Free Consultant
              </button>
            </div>
            <div className="col-lg d-flex justify-content-center mt-5">
              <img
                src={require("../images/custsoftdev.png")}
                alt=""
                className="img-fluid w-75"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Section2 of api page */}
      <div className="container-fluid bgfootcol mt-5 mb-4">
        <div className="col-lg-12">
          <div className="row justify-content-center">
            <div className="col-lg">
              <img
                src={require("../images/egimg1.png")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="col-lg d-flex flex-column justify-content-center align-items-lg-start align-items-center me-lg-5">
              <div className="sect-5-heading pt-3 text-lg-start text-center whitecolor">
                Trusted Mobile Application Development Company
              </div>

              <div className="subheadings f17 pt-3 text-lg-start text-center pb-2 colwhite">
                Mobile Application development is one of the most sensitive
                areas in automation and therefore requires your special
                attention. Especially mobile usability is a complex topic that
                requires lots of refining, intense user research, and even more
                testing with real users. It is important that your app easy to
                use; otherwise, it might end up with low ratings, which cause
                damage to the app’s and possibly your company’s reputation.
                Syntrino offers you just that, and more.
              </div>
              <div className="sect-1-button d-flex justify-content-center align-items-center my-4">
                Get Started
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section3 of api page */}

      {/* <div className="container">
        <div className="col-lg-12 py-5 px-4 d-flex flex-column align-items-center">
          <div className="api-sec-3-heading text-center pb-3">
            Trusted By 100+ Happy Clients Including These Fortune Companies
          </div>
          <div className="row shadow-lg my-4">
            <div class="d-flex flex-column justify-content-Center align-items-center  col-md col-12 my-3 px-sm-4 pe-lg-1 mx-lg-1">
              <img
                src={require("../images/apisect3img1.png")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-md col-12 my-3 px-sm-4 px-lg-1 mx-lg-1">
              <img
                src={require("../images/apisect3img2.png")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-md col-12 my-3 px-sm-4 ps-lg-1 mx-lg-1">
              <img
                src={require("../images/apisect3img3.jpg")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-md col-12 my-3 px-sm-4 ps-lg-1 mx-lg-1">
              <img
                src={require("../images/apisect3img4.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* Section4 of Api */}

      <div className="container">
        <div className="col-lg-12 px-4 d-flex flex-column align-items-center ">
          <div className="api-sec-3-heading pt-3 d-flex flex-column ">
            Custom Mobile App Development Offerings
          </div>
          <div className="row pt-4">
            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-3"></div>
              <div className="sect-2-heading-2  py-3">Mobile App Testing</div>
              <div className="subheadings text-center">
                While testing mobile applications, we shift our focus from
                traditional application security, where the primary threat is
                from multiple sources over the Internet
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-4"></div>
              <div className=" sect-2-heading-2  py-3">
                Mobile App Integration
              </div>
              <div className="subheadings text-center">
                Our developers combine API’s and mobile apps through standard
                app integration services by connecting third-party build
                best-in-class services to your mobile app software.
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-5"></div>
              <div className="sect-2-heading-2  text-center py-3">
                Android App Development
              </div>
              <div className="subheadings text-center">
                Our JAVA developers build best-in-class Android app that looks
                mobile apps through standard elegant on Android screen
                resolution combined with UX design
              </div>
            </div>
          </div>
          <div className="row pt-lg-4">
            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-6"></div>
              <div className="sect-2-heading-2  py-3">iOS App Development</div>
              <div className="subheadings text-center">
                We built an iOS mobile application that runs on iPhone, iPad,
                iPod Touch hardware using integrated developers combine API’s
                mobile app Android app that technology
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-7"></div>
              <div className="sect-2-heading-2  py-3">
                Mobile App Architecture
              </div>
              <div className="subheadings text-center">
                As a mobile app development company we help the client select
                the right architecture for mobile app with broadening to scope
                of connectivity
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-8"></div>
              <div className="sect-2-heading-2  py-3">
                {" "}
                Mobile App UI/UX Design
              </div>
              <div className="subheadings text-center">
                Success of mobile app development depends on user-experience. We
                help to establish mobile UX design principle optimized to user
                needs.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section5 of Api */}
      <div className="container-fluid d-flex flex-column align-items-center bg-danger mt-5 position-relative height-sect-5">
        <div className="sect-4-heading text-lg-start text-center">
          Benefits Of Working With KPN
        </div>
        <div className="container px-5 col-lg-12 pb-5 d-lg-flex flex-lg-column align-items-lg-center pt-5">
          <div className="row">
            <div className="col-lg d-flex flex-lg-row flex-column align-items-lg-start align-items-center bg-sec5-1 m5  py-5 px-5 ">
              <img
                src={require("../images/sect2img01.png")}
                alt=""
                className="img-fluid me-lg-3"
              />
              <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
                <div className="api-sec-5-heading text-lg-start text-center">
                  New business opportunities at your doorstep
                </div>

                <div className="subheadings f17 mt-2 text-lg-start text-center">
                  Ensuring you identify and unlock opportunities is our job. We
                  develop applications that harness the power of enterprise
                  solutions and deliver the best in class user experience.
                </div>
              </div>
            </div>

            <div className="col-lg d-flex flex-lg-row flex-column align-items-lg-start align-items-center bg-sec5-1 m5  py-5 px-5">
              <img
                src={require("../images/sect2img02.png")}
                alt=""
                className="img-fluid me-lg-3"
              />

              <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
                <div className="api-sec-5-heading text-lg-start text-center">
                  Consultations and strategy
                </div>

                <div className="subheadings f17 mt-2   text-lg-start text-center">
                  We take great interest in understanding your business needs,
                  streamline processes that need an upgrade, redefine the user
                  experience and develop a comprehensive roadmap that not only
                  aligns with your goals.
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-lg-5">
            <div className="col-lg d-flex flex-lg-row flex-column align-items-lg-start align-items-center bg-sec5-1 m5 py-5 px-5">
              <img
                src={require("../images/sect2img03.png")}
                alt=""
                className="img-fluid me-lg-3"
              />
              <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
                <div className="api-sec-5-heading text-lg-start text-center">
                  Increasing your consumer retention and ROI
                </div>

                <div className="subheadings f17 mt-2  text-lg-start text-center">
                  Syntrino ensures that your ideas nourish and turn into
                  products with high output and retention rates. We use a host
                  of combinations with research, design thinking and testing to
                  bring high-potential use cases.
                </div>
              </div>
            </div>

            <div className="col-lg d-flex flex-lg-row flex-column align-items-lg-start align-items-center bg-sec5-1 m5  py-5 px-5">
              <img
                src={require("../images/sect2img04.png")}
                alt=""
                className="img-fluid me-lg-3"
              />

              <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
                <div className="api-sec-5-heading text-lg-start text-center">
                  Best security practices
                </div>

                <div className="subheadings f17 mt-2  text-lg-start text-center">
                  We adopt best practice to reduce security risks, tests
                  potential vulnerabilities and encrypts/sanitizes data before
                  use. develop a comprehensive roadmap harness the power of
                  enterprise You can be confident that your are in safe hands.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section X */}

      <div className="container">
        <div className="col-12">
          <div className="row pt-5">
            <div className="col-lg-6 d-flex flex-column align-items-start">
              <div className="sect-4-heading colblack">
                How Do We Develop Custom Software With Agile:
              </div>
              <img
                src={require("../images/sectionx.jpg")}
                alt=""
                className="img-fluid me-lg-3"
              />
            </div>

            <div className="d-flex flex-column align-items-center col-lg pt-lg-4 mt-lg-5">
              <div class="d-flex flex-column justify-content-center align-items-center my-3  mx-auto px-sm-4 mx-lg-1 sec-2-cards">
                <div className="sect-2-img-2"></div>
                <div className="sect-2-heading-2  py-3">
                  Mobile App Strategy
                </div>
                <div className="subheadings text-center">
                  Mobile apps we built are secure set to define your business
                  roadmap.
                </div>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center my-3  mx-auto px-sm-4 mx-lg-1 sec-2-cards">
                <div className="sect-2-img-5"></div>
                <div className="sect-2-heading-2  py-3">
                  User Experience Design
                </div>
                <div className="subheadings text-center">
                  Our prototype engineers crease a manipulating user behaviour
                  design.
                </div>
              </div>
            </div>

            <div className="d-flex flex-column align-items-center col-lg">
              <div class="d-flex flex-column justify-content-center align-items-center my-3  mx-auto px-sm-4 mx-lg-1 sec-2-cards">
                <div className="sect-2-img-6"></div>
                <div className="sect-2-heading-2  py-3">
                  Mobile Bugs Handling
                </div>
                <div className="subheadings text-center">
                  Our mobile quality analyst engineers ensure tested product
                  over numerous bugs testing modules
                </div>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center my-3  mx-auto px-sm-4 mx-lg-1 sec-2-cards">
                <div className="sect-2-img-7"></div>
                <div className="sect-2-heading-2  py-3">
                  Support & Maintenance
                </div>
                <div className="subheadings text-center">
                  Our mobile app development company offers 24*7 support &
                  maintenance after launch
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section6 of Api */}

      <div className="container mt-5">
        <div className="api-sec-6-heading pb-2 pt-3 d-flex justify-content-center">
          Industries We Serve
        </div>
        <div className="subheadings colblack text-center pb-5">
          We provide a range of services across industries, including the
          following:
        </div>

        <div className="row">
          <div className="col-lg-12 d-flex sect-6-btn-container">
            <div className="">
              <button
                onClick={handleClick}
                className="button1"
                id="sect-6-api-btn"
              >
                Transportation
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button2"
                id="sect-6-api-btn"
              >
                Finance
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button3"
                id="sect-6-api-btn"
              >
                Healthcare
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button4"
                id="sect-6-api-btn"
              >
                Tourism
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button5"
                id="sect-6-api-btn"
              >
                Consumer
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button6"
                id="sect-6-api-btn"
              >
                Retail
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button7"
                id="sect-6-api-btn"
              >
                Education
              </button>
            </div>
          </div>
          <hr />

          <div className="row">
            {collapse === 1 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4"></div>

                <div className="subheadings pt-2 w479 fs18">
                  Our services span indoor stadium management platforms for
                  storage, location, and retrieval of information, ticketing
                  systems, crowd control systems, and facility management
                  systems, etc.
                </div>
              </div>
            )}
            {collapse === 2 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4"></div>

                <div className="subheadings pt-2 w479 fs18">
                  We offer mobile CRM, an operational, analytical and
                  collaborative software that ensures the bank managers can
                  manage their clients effortlessly
                </div>
              </div>
            )}
            {collapse === 3 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4"></div>

                <div className="subheadings pt-2 w479 fs18">
                  We offer an integrated mobile system for managing hospitals,
                  including services such as medical, administrative, financial,
                  legal and other corresponding services.
                </div>
              </div>
            )}
            {collapse === 4 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4"></div>

                <div className="subheadings pt-2 w479 fs18">
                  A mobile app for restaurants and hotels to speed up their
                  checkout process, accept credit cards payments, track sales,
                  inventory and manage other business function.
                </div>
              </div>
            )}

            {collapse === 5 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4"></div>

                <div className="subheadings pt-2 w479 fs18">
                  An API-driven instant messaging & video conference that
                  ensures higher connectivity with real time data communication
                  built over server-less technology.
                </div>
              </div>
            )}

            {collapse === 6 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4"></div>

                <div className="subheadings pt-2 w479 fs18">
                  Mobile app for supply chain management for multiple locations,
                  safety stocks, reorder points, cycle counts, demand &
                  distribution requirement planning
                </div>
              </div>
            )}

            {collapse === 7 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4"></div>

                <div className="subheadings pt-2 w479 fs18">
                  E-learning mobile app with user-centred design standard for
                  content delivery and assessment process with high security,
                  accessibility, and data maintenance.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Section7 of Api */}

      {/* <div class="container mt-5 pt-5">
        <div className="api-sec-6-heading pb-3 pt-4 d-flex justify-content-center">
          Our API Solutions
        </div>

        <p className="subheadings text-center mb-5 fs18">
          Integrate & Develop bespoke API with KPN Solutions
        </p>
        <div class="row">
          <div class="col-sm-4">
            <div class="card card-flip h-100 rounded-4">
              <div class="card-front text-white colred rounded-4">
                <div class="card-body  d-flex flex-column align-items-center my-5">
                  <i class="fa fa-search fa-5x float-right"></i>
                  <h3 class="card-title">Payment Gateways</h3>
                  <p class="card-text">Paypal</p>
                  <p class="card-text">Authorize.net</p>
                  <p class="card-text">2Checkout</p>
                  <p class="card-text">BillDesk</p>
                  <p class="card-text">PayU</p>
                  <p class="card-text">EBS</p>
                  <p class="card-text">Sage Pay</p>
                </div>
              </div>
              <div class="card-back bg-white">
                <div class="card-body">
                  <h3 class="card-title">Back</h3>
                  <p class="card-text">
                    Suprise this one has more more more more content on the
                    back!
                  </p>
                  <a href="#" class="btn btn-outline-secondary">
                    Action
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card card-flip h-100 rounded-4">
              <div class="card-front text-white colred rounded-4">
                <div class="card-body  d-flex flex-column align-items-center my-5">
                  <i class="fa fa-search fa-5x float-right"></i>
                  <h3 class="card-title">Payment Gateways</h3>
                  <p class="card-text">Paypal</p>
                  <p class="card-text">Authorize.net</p>
                  <p class="card-text">2Checkout</p>
                  <p class="card-text">BillDesk</p>
                  <p class="card-text">PayU</p>
                  <p class="card-text">EBS</p>
                  <p class="card-text">Sage Pay</p>
                </div>
              </div>
              <div class="card-back bg-dark text-white">
                <div class="card-body">
                  <h3 class="card-title">Back #2</h3>
                  <p class="card-text">
                    Suprise this one has content on the back!
                  </p>
                  <a href="#" class="btn btn-outline-secondary">
                    Action
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card card-flip h-100 rounded-4">
              <div class="card-front text-white colred rounded-4">
                <div class="card-body  d-flex flex-column align-items-center my-5">
                  <i class="fa fa-search fa-5x float-right"></i>
                  <h3 class="card-title">Payment Gateways</h3>
                  <p class="card-text">Paypal</p>
                  <p class="card-text">Authorize.net</p>
                  <p class="card-text">2Checkout</p>
                  <p class="card-text">BillDesk</p>
                  <p class="card-text">PayU</p>
                  <p class="card-text">EBS</p>
                  <p class="card-text">Sage Pay</p>
                </div>
              </div>
              <div class="card-back bg-white">
                <div class="card-body text-primary">
                  <h3 class="card-title">Wow! #3</h3>
                  <p class="card-text">
                    Suprise this one has content on the back!
                  </p>
                  <a href="#" class="btn btn-outline-primary">
                    Action
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Section8 of Api */}

      <div className="container mt-2">
        <div className="api-sec-6-heading pb-5 pt-5 d-flex justify-content-center">
          Frequently Asked Question (FAQ)
        </div>
        <div className="col-lg-12">
          <div className="row justify-content-center">
            <div className="col-lg-5 width-fit-con">
              <Accordion className="shadow">
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="0">
                      What is the difference between Mobile vs. Desktop Web
                      Application Testing?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="w604">
                      The main difference between a mobile and desktop client is
                      that, in the mobile case, the Web content is interpreted
                      by a Web browser that runs on a client computer. This type
                      of browser has many restrictions that a typical desktop
                      client does not have, mainly since it runs on a mobile
                      device and often uses a wireless network with low
                      bandwidth. Overcoming these restrictions, requires
                      creative designing and programming solutions. Some device
                      limitations while testing mobile web applications are- ●
                      Small screen size ● Restricted CPU power ● Limited RAM
                      (Random Access Memory) ● Limited secondary storage ● Power
                      management dependencies ● Limited battery life
                      dependencies ● Cumbersome UI
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="1">
                      What things I should keep in mind while using mobile web
                      application development tools?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body className="w604">
                      Due to the unique nature of mobile web application testing
                      which depends greatly on the client hardware, it is useful
                      to have emulators to help test the application prior to
                      the actual deployment on the physical device. Two useful
                      types of emulators are device emulators and browser
                      emulators. Most vendors that license their operating
                      systems or development platforms offer a device emulator.
                      Since the devices are OS-based, given the same operating
                      system, you might be able to emulate different types of
                      devices by changing the skin. The term skin is used to
                      describe the capability to change the outside (the ‘skin’)
                      of a device, while leaving the inside (the operating
                      system) the same, to give you a different device.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="2">
                      Are there any testing considerations I should be aware of?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body className="w604">
                      Yes, although we offer these solutions, some
                      considerations which may require further deliberation
                      include: - How do the test devices manage their cache? Is
                      it user-configurable? -How do you determine the expected
                      results for the way content is displayed across platforms
                      and browsers? - External links: How are they handled in
                      offline browsing? - Memory-full testing or stress testing:
                      How do you execute memory-full and near-full test cases on
                      the test platforms? - How do you write reusable test cases
                      for different devices? - If your Web site supports
                      multiple formats, including WML, i-Mode and HTML, which
                      rules does a browser use to determine which content to
                      load? - Some sites recognize the browser version to
                      determine compatibility. Are you checking for
                      compatibility? -Does the browser properly interpret
                      tables, frames, lists, checkboxes, drop-downs, scripts,
                      and so on?
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="3">
                      How Syntrino ensure quality mobile application product
                      delivery?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body className="w604">
                      While at Syntrino, we use the following basic mantra for
                      long lasting success of your products: ● Less Is More:
                      When testing the usability of an app, this is the design
                      principle we follow. We check the app for useless
                      navigation elements and buttons and talk to your team or
                      the usability expert and get them removed. The same
                      applies to text and words. Unlike others, we try to focus
                      on the primary content of the app and keep the goal and
                      the problem the app should solve in mind and get rid of
                      anything that is distracting. ● Detailed and Explanatory:
                      We check if the app is self-explanatory and ask ourselves
                      these questions: - Is the user able to see the elements
                      with which he or she can interact? - Is every element on
                      the screen clear and understandable? ● Attention to
                      Patterns: We believe that every app should follow a
                      usability and design pattern. If such a pattern is in
                      place, we verify that your app follows those rules. If
                      there is no pattern available, we keep an eye on the
                      look-and-feel of the append check that all of the elements
                      are accessible.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="4">
                      What if I do not have a project spec or job description
                      written out?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body className="w604">
                      Whether your project is at the early ideation stage or you
                      have all features drawn out, our tech consultation team
                      works with you to prepare a detailed tech solution and
                      execution plan. We are huge believers in high output
                      management and everything we suggest from tech
                      architecture to talent skill set will focus on getting
                      results and speeding up the time to market.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="5">
                      How is KPN different from other online talent
                      marketplaces, local agencies, and software consulting
                      companies?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body className="w604">
                      Freelance marketplaces like Upwork, Toptal, Gigster,
                      Freelancer, etc can help you get in touch with thousands
                      of developers. They provide transparent access to devs but
                      you have to vet candidates and take care of everything.
                      These platforms do not provide a guarantee of delivery and
                      results. Tech agencies and software consultancies follow
                      outdated engagement and execution models. The teams and
                      devs hired through them don't integrate with your team
                      well. Transparency and collaboration are lacking. We
                      started Syntrino with the goal of bringing assurance,
                      certainty, and transparency in the software development
                      services space. Most of our competitors provide
                      TRANSACTIONAL services. You post a gig, they match you up
                      with a developer and you take it from there. We aren't a
                      transactional service.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="6">
                      How do you design mobile apps?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="6">
                    <Card.Body className="w604">
                      Even the most visually amazing app can and will fail to
                      perform well on the app store if it’s not intuitive and
                      lacks usability. As a user, you would want to open an app
                      and feel like you already intuitively know
                      everything — where to click next, what actions to perform,
                      how to jump to a particular place, etc.The second you get
                      stuck, you give up and go try another app. Sounds
                      familiar? So what do we do to make sure this doesn’t
                      happen to the potential users you just spent so much time
                      researching? We do UX. What is UX Design? UX is the
                      process of creating products that provide meaningful and
                      relevant experiences to users. This involves the design of
                      the entire process of acquiring and integrating the
                      product, including aspects of branding, design, usability,
                      and function — The Interaction Design Foundation UX design
                      is an ongoing process. With each update, you should
                      consider the way people are using your app. If an update
                      makes the accessibility of your app complex or increases
                      the number of taps to the users’ destination, you’re
                      heading off course and it’s time to correct. Let’s get
                      into some of the UX design process. Step 1: Information
                      Architecture Simply put, Information Architecture (or IA)
                      is figuring out how all the content should be structured
                      to maximize accessibility and ease of use. It is the user
                      journey, the path that users will take to perform their
                      intended action. It is in this stage that you decide which
                      features will help users go through the journey, as well
                      as overall functionality of your app. You also decide how
                      to present and organize this information. Typically, the
                      IA process begins by writing down the list of desired
                      features and a some rough sketches of what needs to be
                      displayed and where in the app. The outcomes of this step
                      are user flows and user journeys which serve as building
                      blocks for creating wireframes. Step 2: Wireframing your
                      App A wireframe, also called a page schematic or screen
                      blueprint (just kidding, no one actually calls them that),
                      is a visual guide that shows the skeletal framework of an
                      app. It gives you an idea on the infamous “look and feel”.
                      With a wireframe in front of you, it’s easy to explain the
                      concept to your dev team and set reasonable expectations
                      from the start. Wireframing eases the often messy
                      transition between the stages of the project. It is easier
                      and cheaper to erase stuff from a sketch than it is to
                      rewrite code. Good wireframes are essential in helping you
                      to launch your app more effectively. Step 4: Clickable
                      prototype with UI Now that you have some screens to play
                      with, you’re going to want to familiarize yourself with
                      InVision, an essential tool of the product designer’s
                      toolbox. This isn’t an InVision guide, but InVision is so
                      easy to use that it doesn’t need one. Just upload your
                      screens to InVision and link them together.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="7">
                      How do you choose mobile app architecture?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="7">
                    <Card.Body className="w604">
                      These are the things to keep in mind when choosing mobile
                      app architecture. Portability Portability is the ability
                      of the system to react to the environmental changes. In
                      case of Mobile applications, the change in environment may
                      be frequent considering the technology and market demand
                      changes. These changes may result in change in servers,
                      database, etc. The good Mobile Application Architecture
                      ensures the system to be portable enough to respond to
                      these changes keeping the impact of change at minimal
                      level. Maintainability Considering the requirement change
                      due to change in environment i.e. market demand or the
                      ease with which a component can be modified to correct
                      faults, improve performance, or other attributes, always
                      there is a need of maintenance for the Mobile
                      Applications. A Good Mobile Application Architecture
                      ensures the high maintainability of the mobile
                      application. It reduces the efforts and complexity of the
                      change implementation which is in favor of the developers.
                      Manageability How efficiently and easily a software system
                      can be monitored and maintained to keep the system
                      performing, secure, and running smoothly. Reusability
                      Reusability always leads to faster application development
                      and structured development approach. Good Architecture
                      always considers the reusability aspect during design of
                      components and protocols. Testability The application
                      needs to undergo testing process in order to ensure the
                      consistency of the application under various conditions.
                      This raises a need of regression testing for each
                      component of the system. Good Mobile Application
                      Architecture ensures that the each component is separately
                      testable. Security Security of the data is one of the
                      major non-functional requirement of the application. The
                      application architecture should be robust enough to secure
                      the data consumed by the application. The architecture of
                      Mobile Application should be in sync with organization’s
                      security ecosystem. Any data stored on the device
                      (in-memory or persistent) should be encrypted to ensure
                      security. The organization may opt for Mobile Device
                      Management (MDM) tool to impose security constraints on
                      the application. The application architecture should be
                      able to accommodate any interface to such Mobile Device
                      Management tools. Performance The performance of
                      application plays an important role in mobile
                      applications. Mobile users expects quick response from the
                      application specifically compared to desktop/web
                      applications. If the application is taking long time to
                      retrieve or display the details, there is a high
                      possibility that the users would not like the application
                      and they might stop using the application. A mobile
                      application architecture should ensure to meet the
                      performance expectations of the users. While making
                      architectural decisions, we need to keep these in mind as
                      well. 1. Components of the app should be nicely organized
                      and decoupled. 2. Architecture should speak about the
                      business domain of the project. Is it a food delivery
                      application Or maybe one for financial institutions? 3.
                      Scalability: How easy it is to add more features? Having
                      an elegant solution might save us a lot of time and money
                      in the future. 4. How the architecture fits the
                      requirements of the business domain. Is it a heavy data
                      driven app? Does it have a lot of forms that require user
                      input? What is the complexity of the app that we are going
                      to build? Is it a “5 screens” app or “20 screens” app? 5.
                      Will team be able to work independently on features,
                      without blocking themselves? 6. What are the key
                      components that we want to test? 7. What is the deadline
                      and budget for the project? What is the trade-off that we
                      must do in terms of quality — time of delivery? 8.
                      Consider all network scenarios.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="8">
                      What are the Mobile Web Application Tests KPN Offer?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="8">
                    <Card.Body className="w604">
                      We adhere to offering following best practice: 1. Add-on
                      Tests: Thick-client mobile devices such as PDAs and
                      smartphones offer secondary storage and the capability to
                      install add-on software including the browser itself,
                      other client-side components as well as templates. You
                      need to consider the implications of the installation of
                      these elements and design test cases to cover various
                      installation scenarios, including cross operating systems,
                      cross devices, cross releases of browsers, and so on.
                      Syntrino offers these solutions on-the-go. 2. Data
                      Synchronization-Related Tests: Due to the fact that
                      wireless bandwidth is often limited, we provide design
                      solutions to download web content for offline browsing.
                      Syntrino performs this synchronization process in two
                      ways: A. The method for downloading data onto the mobile
                      device that does not have a connection to the Internet or
                      network is to synchronize with the desktop application. In
                      this case, the desktop already has a connection to the
                      Internet. In turn, web content on the desktop can be
                      transferred over to the device via a data synchronization
                      process. B. Synchronization can also be done wirelessly.
                      We assist you in synchronizing your device with a proxy
                      server via a wireless modem connection. Pre-formatted web
                      pages stored on web servers connected to the proxy server
                      can then be transferred to the device during the
                      synchronization process. 3. UI Implementation and Limited
                      Usability Tests: Some of the factors that affect the
                      usability judgments include the target audience to which
                      you market and the limitation of the devices, such as
                      screen size, bandwidth, device-specific and
                      website-specific technologies. Therefore, in testing for
                      UI implementation and usability errors, we provide
                      solutions to some dire problems that may arise, including
                      some pressing questions such as: ● How do the data input
                      methods on supported platforms affect usability? ● How
                      does the Web site under test appear in the browser with
                      default settings for attributes such as page size, images,
                      text size, and so on? ● Does text-wrapping work properly?
                      ● Is the navigation intuitive? ● Are the input interfaces
                      consistent and friendly? ● Is there consistency among
                      menu, button, and error-handling implementations? ● Are
                      the graphics well fit and positioned in the display? 4.
                      Browser-Specific Tests: Some of the mobile application
                      testing issues that are worth reiterating here, and for
                      which Syntrino offers services in developing test cases to
                      seek out problems, include- - The side effects introduced
                      by the application under test - Incompatibility or lack of
                      support for one or more markup languages, such as HTML,
                      WML, cHTML, xHTML, HTML, or XML. - Support for PQA or Web
                      Clipping-based applications - Legacy support issues -
                      Caching issues 5. Platform-Specific Tests: When your
                      company develops an application for a particular platform,
                      such as Windows, UNIX, MacOS, Palm Computing, or Windows
                      PocketPC, you need to execute these tests to seek out bugs
                      that are platform-specific rather than
                      functionality-specific. Although the scope and depth of
                      platform-specific testing will be dictated by the test
                      requirements or objectives, we offer standardized
                      platform-specific tests and several other vendor-driven
                      standards for mobile applications. 6. Configuration and
                      Compatibility Tests: Syntrino offers customized solutions
                      across a range of products, including: ● Cross devices ●
                      Cross operating systems including standards and OEMs ●
                      Cross browsers ● Cross versions ● Mobile Testing Support
                      Tools ● Graphic formats etc. 7. Connectivity Tests: Our
                      connectivity tests involve seeking out errors related to
                      how the device and the network can be connected to each
                      other; and while being connected, how interference during
                      data transmissions can cause functionality as well as data
                      integrity, failures.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
            <div className="col-lg-5 d-flex flex-column align-items-center justify-content-center mt-5 pb-lg-5 mb-lg-5">
              <h1 className="sect-5-heading text-center">
                Have more questions?
              </h1>
              <p className="subheadings text-center w194">
                Let us know and our experts will get in touch with you ASAP.
              </p>

              <div className="sect-1-button d-flex justify-content-center align-items-center">
                Contact Us
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section9 of Api */}
      <div className="py-5 mt-4 mb-3">
        <h1 className="sect-5-heading mx-4 text-center mt-5 mb-4">
          KPN Guarantee
        </h1>
        <p className=" subheadings text-center mx-5">
          We know that if the client’s project launches smoothly, they’ll come
          back for more.We’re willing to over-invest in guaranteeing results,
          rather than under-invest to make our financial reports look pretty in
          the short-run.
        </p>

        <p className="subheadings text-center mx-5 pt-2">
          We offer a risk-free trial period of up to two weeks. You will only
          have to pay if you are happy with the developer and wish to continue.
          If you are unsatisfied, we’ll refund payment or fix issues on our
          dime.
        </p>
      </div>

      {/* Section10 of Api */}

      <div className="container bg-2-sect-4 mb-5">
        <div className=" col-lg-12 pt-3 pb-4">
          <div className="row align-items-center justify-content-center">
            <div className="d-lg-flex flex-column pt-4 pb-1 text-center text-lg-start col-lg-6">
              <h1 className="sect-4-heading-3">
                {" "}
                Hire the best developers and designers around!
              </h1>
              <p className="subheadings f22">We Deliver the best</p>
            </div>

            <div className="dcontents col-lg-6 pt-lg-0 pt-4">
              <div className="subheadings shadow w240 pt-2 mt-4 mb-sm-3 mb-lg-0 text-center sect-4-btn">
                HIRE TOP DEVELOPERS
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MobAppDev;
