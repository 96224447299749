import React from "react";
import "../fonts/Poppins/Poppins-Black.ttf";
import "../styles/main.css";

function Input(props) {
  return (
    <>
     <input type={props.type} className="user" value={props.value} name={props.name} placeholder={props.placeholder}/>
    </>
  );
}

export default Input;
