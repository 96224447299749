import React from "react";
import { useState } from "react";
import "../styles/main.css";
import Footer from "../components/Footer";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Getintouch from "../components/Getintouch";
import Navb from "../components/Navb";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );

  return (
    <button
      className="accord-btn my-2"
      type="button"
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

const AppDevServ = () => {
  const [collapse, setCollapse] = useState(1);

  function handleClick(e) {
    if (e.target.className === "button1") {
      setCollapse(1);
    } else if (e.target.className === "button2") {
      setCollapse(2);
    } else if (e.target.className === "button3") {
      setCollapse(3);
    } else if (e.target.className === "button4") {
      setCollapse(4);
    } else if (e.target.className === "button5") {
      setCollapse(5);
    } else if (e.target.className === "button6") {
      setCollapse(6);
    } else if (e.target.className === "button7") {
      setCollapse(7);
    }
  }

  const [pop, setPop] = useState(false);

  function formPop() {
    setPop(true);
  }

  return (
    <>
      <Navb />
      {pop && <Getintouch setPop={setPop} />}
      {/* Section1 of api page */}
      <div className="container-fluid">
        <div className="col-12">
          <div className="row">
            <div className="col-lg pt-5 pb-5 ps-5 d-flex flex-column">
              <h1 className="sect-1-heading">Quality Application</h1>
              <h1 className="sect-1-heading">Development Services</h1>
              <h1 className="sect-1-heading">to Deliver Results</h1>
              <p className="subheadings sub-1-width">
                KPN expert software engineers build custom web applications
                with a competitive edge.
              </p>
              <button
                onClick={formPop}
                className="sect-1-button d-flex justify-content-center align-items-center border-0"
              >
                Get Started
              </button>
            </div>
            <div className="col-lg d-flex justify-content-center mt-5">
              <img
                src={require("../images/appdevserv.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Section2 of api page */}
      <div className="container-fluid bgfootcol mt-5 mb-4">
        <div className="col-lg-12">
          <div className="row justify-content-center">
            <div className="col-lg">
              <img
                src={require("../images/egimg1.png")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="col-lg d-flex flex-column justify-content-center align-items-lg-start align-items-center me-lg-5">
              <div className="sect-5-heading pt-3 text-lg-start text-center whitecolor">
                Hire Reliable Application Development Services
              </div>

              <div className="subheadings f17 pt-3 text-lg-start text-center pb-2 colwhite">
                KPN comprise of talented application developers with
                industry expertise. We are ready to offer you custom application
                development services for churn up smart and scalable
                product-engineers.
              </div>
              <div className="sect-1-button d-flex justify-content-center align-items-center my-4">
                Get Started
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section3 of api page */}

      {/* <div className="container">
        <div className="col-lg-12 py-5 px-4 d-flex flex-column align-items-center">
          <div className="api-sec-3-heading text-center pb-3">
            Trusted By 100+ Happy Clients Including These Fortune Companies
          </div>
          <div className="row shadow-lg my-4">
            <div class="d-flex flex-column justify-content-Center align-items-center  col-md col-12 my-3 px-sm-4 pe-lg-1 mx-lg-1">
              <img
                src={require("../images/apisect3img1.png")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-md col-12 my-3 px-sm-4 px-lg-1 mx-lg-1">
              <img
                src={require("../images/apisect3img2.png")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-md col-12 my-3 px-sm-4 ps-lg-1 mx-lg-1">
              <img
                src={require("../images/apisect3img3.jpg")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-md col-12 my-3 px-sm-4 ps-lg-1 mx-lg-1">
              <img
                src={require("../images/apisect3img4.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* Section4 of Api */}

      <div className="container">
        <div className="col-lg-12 px-4 d-flex flex-column align-items-center ">
          <div className="api-sec-3-heading pt-3 d-flex flex-column ">
            Best Mobile & Web Application Development Services
          </div>
          <div className=" subheadings f17 colblack">
            All your web and mobile application needs to be made easy
          </div>
          <div className="row pt-4">
            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-3"></div>
              <div className="sect-2-heading-2  py-3">
                Hybrid App Development
              </div>
              <div className="subheadings text-center">
                We create the best hybrid application performing functions like
                Geofencing, GPS, chatting, scheduling, and other functions
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-4"></div>
              <div className=" sect-2-heading-2  py-3">
                Android App Development
              </div>
              <div className="subheadings text-center">
                We offer Full Stack Android App development services in India to
                meet your business and operational requirement across platforms.
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-5"></div>
              <div className="sect-2-heading-2  text-center py-3">
                iOS App Development
              </div>
              <div className="subheadings text-center">
                KPN expertise in all kind of iOS application development
                services using marvels technology that host iOS app design in
                integration with the native environment.
              </div>
            </div>
          </div>
          <div className="row pt-lg-4">
            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-6"></div>
              <div className="sect-2-heading-2  py-3">iOS App Development</div>
              <div className="subheadings text-center">
                KPN expertise in all kind of iOS application development
                services using marvels technology that host iOS app design in
                integration with the native environment.
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-7"></div>
              <div className="sect-2-heading-2  py-3">
                Offshore App Development
              </div>
              <div className="subheadings text-center">
                Offshore application development helps the business to reduce
                operating cost and improve productivity by leveraging offshore.
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-8"></div>
              <div className="sect-2-heading-2  py-3">
                {" "}
                SaaS Web Application
              </div>
              <div className="subheadings text-center">
                We offer competent Software as a Service application development
                solution in any platform tailor-made to your website Development
                requirement.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section5 of Api */}
      <div className="container-fluid d-flex flex-column align-items-center bg-danger mt-5 position-relative height-sect-5">
        <div className="sect-4-heading text-lg-start text-center">
          Why Choose KPN As The Right Technology Partner?
        </div>
        <div className="container px-5 col-lg-12 pb-5 d-lg-flex flex-lg-column align-items-lg-center pt-5">
          <div className="row">
            <div className="col-lg d-flex flex-lg-row flex-column align-items-lg-start align-items-center bg-sec5-1 m5  py-5 px-5 ">
              <img
                src={require("../images/sect2img01.png")}
                alt=""
                className="img-fluid me-lg-3"
              />
              <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
                <div className="api-sec-5-heading text-lg-start text-center">
                  Competitive pricing and innovative concept
                </div>

                <div className="subheadings f17 mt-2 text-lg-start text-center">
                  We ensure the business get expert to enable app development at
                  a reasonable cost. Thus, benefits clients establish market
                  position with cohesive pricing strategy.
                </div>
              </div>
            </div>

            <div className="col-lg d-flex flex-lg-row flex-column align-items-lg-start align-items-center bg-sec5-1 m5  py-5 px-5">
              <img
                src={require("../images/sect2img02.png")}
                alt=""
                className="img-fluid me-lg-3"
              />

              <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
                <div className="api-sec-5-heading text-lg-start text-center">
                  Trending technology & tools
                </div>

                <div className="subheadings f17 mt-2   text-lg-start text-center">
                  KPN mobilize your business by leveraging the latest
                  application technology and software tools. We assure your
                  business will surcharge as we strengthen technology used in
                  mobile app with your unique business needs.
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-lg-5">
            <div className="col-lg d-flex flex-lg-row flex-column align-items-lg-start align-items-center bg-sec5-1 m5 py-5 px-5">
              <img
                src={require("../images/sect2img03.png")}
                alt=""
                className="img-fluid me-lg-3"
              />
              <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
                <div className="api-sec-5-heading text-lg-start text-center">
                  Explore seamless mobility
                </div>

                <div className="subheadings f17 mt-2  text-lg-start text-center">
                  As a leading mobile application development company, we have
                  successfully shipped 120+ apps across the industry. Deploy
                  best application developers and enjoy connectivity, autonomy
                  and innovation.
                </div>
              </div>
            </div>

            <div className="col-lg d-flex flex-lg-row flex-column align-items-lg-start align-items-center bg-sec5-1 m5  py-5 px-5">
              <img
                src={require("../images/sect2img04.png")}
                alt=""
                className="img-fluid me-lg-3"
              />

              <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
                <div className="api-sec-5-heading text-lg-start text-center">
                  Consistent maintain & technical support
                </div>

                <div className="subheadings f17 mt-2  text-lg-start text-center">
                  Our aim is to ensure customer satisfaction at all costs with
                  consistent 24/7 technical support and assistance to all
                  clients, even after app deployment.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section X */}

      <div className="container">
          <div className="col-12">
              <div className="row pt-5">

                <div className="col-lg-6 d-flex flex-column align-items-start">
                <div className="sect-4-heading colblack">
                  How it Works?
                </div>
                <img
                src={require("../images/sectionx.jpg")}
                alt=""
                className="img-fluid me-lg-3"
              />




                </div>
                  

                  <div className="d-flex flex-column align-items-center col-lg pt-lg-4 mt-lg-5">
                <div class="d-flex flex-column justify-content-center align-items-center my-3  mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-2"></div>
              <div className="sect-2-heading-2  py-3">
              Mobile Responsive
              </div>
              <div className="subheadings text-center">
              We ensure you get what you are paying for by delivering integrated app solution with an enterprise solution
              </div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center my-3  mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-5"></div>
              <div className="sect-2-heading-2  py-3">
              Streamlined projection
              </div>
              <div className="subheadings text-center">
              Experience application development in the real world without any screen lag or bugs engrossing in multiple ways
              </div>
            </div>
            </div>



            
            <div className="d-flex flex-column align-items-center col-lg">
                <div class="d-flex flex-column justify-content-center align-items-center my-3  mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-6"></div>
              <div className="sect-2-heading-2  py-3">
                
Dedicated Resource Model
              </div>
              <div className="subheadings text-center">
              Our dedicated infrastructure and skilled professionals who work exclusively on your project
              </div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center my-3  mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-7"></div>
              <div className="sect-2-heading-2  py-3">
              Infinite Replay Value
              </div>
              <div className="subheadings text-center">
              Our team of application developers deliver product with infinite replay value structured to business needs
              </div>
            </div>
            </div>





              </div>


          </div>



      </div>








      {/* Section6 of Api */}

      <div className="container mt-5">
        <div className="api-sec-6-heading pb-5 pt-3 d-flex justify-content-center">
          Solutions We Provide
        </div>

        <div className="row">
          <div className="col-lg-12 d-flex sect-6-btn-container">
            <div className="">
              <button
                onClick={handleClick}
                className="button1"
                id="sect-6-api-btn"
              >
                Fintech
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button2"
                id="sect-6-api-btn"
              >
                Health Tec
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button3"
                id="sect-6-api-btn"
              >
                Real Estate
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button4"
                id="sect-6-api-btn"
              >
                Logistics
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button5"
                id="sect-6-api-btn"
              >
                On-Demand App
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button6"
                id="sect-6-api-btn"
              >
                Fitness
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button7"
                id="sect-6-api-btn"
              >
                Ecommerce
              </button>
            </div>
          </div>
          <hr />

          <div className="row">
            {collapse === 1 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4"></div>

                <div className="subheadings pt-2 w479 fs18">
                  Avail digital banking solution for your finance business from
                  professional app developers in India. We take Fintech industry
                  a serious business by offering our esteemed customers a highly
                  secured atmosphere to carry transactions with ease.
                </div>
              </div>
            )}
            {collapse === 2 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4"></div>

                <div className="subheadings pt-2 w479 fs18">
                  We hardness health sector with revolutionary healthcare
                  technology development services to support online catalyst.
                  Thus, upsurge patients experience by streamlining data records
                  to asset management
                </div>
              </div>
            )}
            {collapse === 3 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4"></div>

                <div className="subheadings pt-2 w479 fs18">
                  Grow your real estate business and reach a vast audience via
                  going digital. We top class real estate application
                  development solution for your business to attract, connect and
                  engage with clients
                </div>
              </div>
            )}
            {collapse === 4 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4"></div>

                <div className="subheadings pt-2 w479 fs18">
                  We understand logistics and transportation business is
                  critically important to you. Therefore, we offer expert supply
                  chain and other application development services for you to
                  manage operational tasks at an affordable price
                </div>
              </div>
            )}

            {collapse === 5 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4"></div>

                <div className="subheadings pt-2 w479 fs18">
                  Launch your business with on-demand application service and
                  support with high-quality technology to escalate your business
                  expertise
                </div>
              </div>
            )}

            {collapse === 6 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4"></div>

                <div className="subheadings pt-2 w479 fs18">
                  Impress your fitness clients to help them reach their fitness
                  goals with our smart fitness app development services with
                  adorable features
                </div>
              </div>
            )}

            {collapse === 7 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4"></div>

                <div className="subheadings pt-2 w479 fs18">
                  Enjoy hands-on-experience and expertise of Digital IT
                  eCommerce solution that will accelerate your business and
                  amplify customers online shopping experience
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Section7 of Api */}

      {/* <div class="container mt-5 pt-5">
        <div className="api-sec-6-heading pb-3 pt-4 d-flex justify-content-center">
          Our API Solutions
        </div>

        <p className="subheadings text-center mb-5 fs18">
          Integrate & Develop bespoke API with KPN Solutions
        </p>
        <div class="row">
          <div class="col-sm-4">
            <div class="card card-flip h-100 rounded-4">
              <div class="card-front text-white colred rounded-4">
                <div class="card-body  d-flex flex-column align-items-center my-5">
                  <i class="fa fa-search fa-5x float-right"></i>
                  <h3 class="card-title">Payment Gateways</h3>
                  <p class="card-text">Paypal</p>
                  <p class="card-text">Authorize.net</p>
                  <p class="card-text">2Checkout</p>
                  <p class="card-text">BillDesk</p>
                  <p class="card-text">PayU</p>
                  <p class="card-text">EBS</p>
                  <p class="card-text">Sage Pay</p>
                </div>
              </div>
              <div class="card-back bg-white">
                <div class="card-body">
                  <h3 class="card-title">Back</h3>
                  <p class="card-text">
                    Suprise this one has more more more more content on the
                    back!
                  </p>
                  <a href="#" class="btn btn-outline-secondary">
                    Action
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card card-flip h-100 rounded-4">
              <div class="card-front text-white colred rounded-4">
                <div class="card-body  d-flex flex-column align-items-center my-5">
                  <i class="fa fa-search fa-5x float-right"></i>
                  <h3 class="card-title">Payment Gateways</h3>
                  <p class="card-text">Paypal</p>
                  <p class="card-text">Authorize.net</p>
                  <p class="card-text">2Checkout</p>
                  <p class="card-text">BillDesk</p>
                  <p class="card-text">PayU</p>
                  <p class="card-text">EBS</p>
                  <p class="card-text">Sage Pay</p>
                </div>
              </div>
              <div class="card-back bg-dark text-white">
                <div class="card-body">
                  <h3 class="card-title">Back #2</h3>
                  <p class="card-text">
                    Suprise this one has content on the back!
                  </p>
                  <a href="#" class="btn btn-outline-secondary">
                    Action
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card card-flip h-100 rounded-4">
              <div class="card-front text-white colred rounded-4">
                <div class="card-body  d-flex flex-column align-items-center my-5">
                  <i class="fa fa-search fa-5x float-right"></i>
                  <h3 class="card-title">Payment Gateways</h3>
                  <p class="card-text">Paypal</p>
                  <p class="card-text">Authorize.net</p>
                  <p class="card-text">2Checkout</p>
                  <p class="card-text">BillDesk</p>
                  <p class="card-text">PayU</p>
                  <p class="card-text">EBS</p>
                  <p class="card-text">Sage Pay</p>
                </div>
              </div>
              <div class="card-back bg-white">
                <div class="card-body text-primary">
                  <h3 class="card-title">Wow! #3</h3>
                  <p class="card-text">
                    Suprise this one has content on the back!
                  </p>
                  <a href="#" class="btn btn-outline-primary">
                    Action
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Section8 of Api */}

      <div className="container mt-2">
        <div className="api-sec-6-heading pb-5 pt-5 d-flex justify-content-center">
          Frequently Asked Question (FAQ)
        </div>
        <div className="col-lg-12">
          <div className="row justify-content-center">
            <div className="col-lg-5 width-fit-con">
              <Accordion className="shadow">
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="0">
                    Why Choose KPN Application Development Services?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="w604">
                      Read five reasons why you need to hire KPN
                      application development services in India. ● We are
                      recognized among leaders for app development in the global
                      market. ● We bring with us more than a decade of
                      experience optimize with client satisfaction. ● Our
                      product engineers ensure implementing the latest
                      technology and tools to deliver top-notch mobile and web
                      development services across all platforms. ● Our team
                      comprised of highly enabled professional developers and
                      designers always ready to support. ● We have successfully
                      provided service to leading companies across industries
                      including global giants
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="1">
                    Why Customer Trust KPN Application Development Services?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body className="w604">
                    KPN application development services compliance to carefully structure design committed to acquire results and solutions. Business encountering problems in the IT sector must innovate business. We are leaders in Android and iOS app development service for higher customer satisfaction, operational expertise, product credibility, security, and cost-effective solution
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="2">
                    What is Different App Development Technologies Do We Use?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body className="w604">
                    As a best app development company, we integrate advanced technologies to stay ahead of the competition. Here are some technologies we integrate into apps are: ● Internet of things: Being top mobile app Development Company we deliver IoT powered solution controlled via mobile that includes real-time tracking, connected business solution, monitoring services, surveillance etc. ● Augmented Reality: Our mobile and web application development services interactive with AR and AI-enabled apps overlaying technology transformation. We employ the latest technology to streamline with audio, GPS, and data. ● Geo-Tracking: Being the best app development company we implement GPS technology enriched with tracking features, geo-fencing, geo-tagging, and location monitoring.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="3">
                    What is the different mobile application you can build? 
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body className="w604">
                    We develop an array of application for your business needs including: Native Apps Development (for Android & iOS) ● Mobile-Web Apps Development ● Hybrid or Cross-Platform Apps Development ● Cross-App Development ● Ionic AngularJs app development ● Xamarin and PhoneGap development ● Mobile game app development ● AR/VR mobile apps development
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="4">
                      How do you use Latest API Technologies ?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body className="w604">
                    We sign strict non-disclosure agreement with your clients and employees to enable the security of your mobile app idea.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
               
              </Accordion>
            </div>
            <div className="col-lg-5 d-flex flex-column align-items-center justify-content-center mt-5 pb-lg-5 mb-lg-5">
              <h1 className="sect-5-heading text-center">
                Have more questions?
              </h1>
              <p className="subheadings text-center w194">
                Let us know and our experts will get in touch with you ASAP.
              </p>

              <div className="sect-1-button d-flex justify-content-center align-items-center">
                Contact Us
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section9 of Api */}
      <div className="py-5 mt-4 mb-3">
        <h1 className="sect-5-heading mx-4 text-center mt-5 mb-4">
          KPN Guarantee
        </h1>
        <p className=" subheadings text-center mx-5">
          We know that if the client’s project launches smoothly, they’ll come
          back for more.We’re willing to over-invest in guaranteeing results,
          rather than under-invest to make our financial reports look pretty in
          the short-run.
        </p>

        <p className="subheadings text-center mx-5 pt-2">
          We offer a risk-free trial period of up to two weeks. You will only
          have to pay if you are happy with the developer and wish to continue.
          If you are unsatisfied, we’ll refund payment or fix issues on our
          dime.
        </p>
      </div>

      {/* Section10 of Api */}

      <div className="container bg-2-sect-4 mb-5">
        <div className=" col-lg-12 pt-3 pb-4">
          <div className="row align-items-center justify-content-center">
            <div className="d-lg-flex flex-column pt-4 pb-1 text-center text-lg-start col-lg-6">
              <h1 className="sect-4-heading-3">
                {" "}
                Hire the best developers and designers around!
              </h1>
              <p className="subheadings f22">We Deliver the best</p>
            </div>

            <div className="dcontents col-lg-6 pt-lg-0 pt-4">
              <div className="subheadings shadow w240 pt-2 mt-4 mb-sm-3 mb-lg-0 text-center sect-4-btn">
                HIRE TOP DEVELOPERS
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AppDevServ;
