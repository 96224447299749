import React from "react";
import "../fonts/Poppins/Poppins-Black.ttf";
import "../styles/main.css";
import Input from "./Input";

function Sect7() {
  return (
    <>
      <div className="container-fluid sec-7-bg pt-lg-5">
        <div className="col-lg-12 py-5">
          <div className="row py-5 pe-lg-5">
            <div className="col-lg-6 d-flex justify-content-center align-items-center">
              <div className="sect-7-heading mb-lg-4 text-lg-start text-center">Avail Free SEO Quotes Now</div>
            </div>

            <div className="d-flex flex-column align-items-center justify-content-center col-lg-6 pe-lg-5">
              <div className=" d-flex justify-content-center pt-4 pt-lg-0 smalldev">
                <Input type="text" name="text" placeholder="First Name" />
                <Input type="number" name="number" placeholder="Phone" />
              </div>

              <div className=" d-flex justify-content-center smalldev">
                <Input type="email" name="email" placeholder="Email ID" />
                <Input type="text" name="text" placeholder="Subject" />
              </div>

                <div>
                    <input type="text" name="text" placeholder="Message" className="message" />
                </div>

                <div className="subheadings d-flex justify-content-center  align-items-center sect-7-button mt-5">
                    Submit

                </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sect7;
