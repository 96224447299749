import React from "react";
import "../fonts/Poppins/Poppins-Black.ttf";
import "../styles/main.css";

function Sect3() {
  return (
    <>

    
<div className="container sect3bcolor">
        <div className=" col-lg-12 py-5 px-4">
          <div className="row justify-content-center">

          <div className="col-lg-6 pt-lg-0 me-lg-5 pt-5 d-flex justify-content-center">
              <img
                src={require("../images/sec3img.jpg")}
                alt=""
                className="img-fluid sec5imgsize"
              />
            </div>

            <div className="d-flex flex-column justify-content-center align-items-lg-start pt-5 align-items-center col-lg-6 mb-lg-5  w374">
              <div className="gradfont text-lg-start text-center">
              Bringing Thoughts to Brands
              </div>

              <div className="sect-5-heading pt-3 text-lg-start text-center">
              Building software product
engineering with agility
              </div>

              <div className="subheadings sec5widthsub pt-3 text-lg-start text-center">
              KPN is a trusted IT outsourcing company to address the critical need for startups, product companies, and global enterprise. Our product engineers and programmers develop a software platform rich with innovation that helps businesses to edge out the competition and grow sustainably.
              </div>
            </div>

           
          </div>
        </div>
      </div>
      

    </>
  );
}

export default Sect3;
