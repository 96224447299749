import React from "react";
import "../fonts/Poppins/Poppins-Black.ttf";
import "../styles/main.css";

function Sect6() {
  return (
    <>
     
     <div className="container-fluid sec-6-bg">
        <div className="col-lg-12 py-5 px-4 d-flex flex-column align-items-center">
          <div className="gradfont pt-4">Bringing Thoughts to Brands</div>
          <div className="sec-6-heading-1 text-center pb-3">
          Meet Our Expert Team
Members
          </div>
          <div className="row">
            <div class="d-flex flex-column justify-content-Center align-items-center  col-md col-12 my-3 px-sm-4 pe-lg-1 mx-lg-1">
              <img
                src={require("../images/sec6img1.png")}
                alt=""
                className="img-fluid"
              />
              <div className=" text-center  pt-4 sect-4-heading-2">Rosana David</div>
              <div className="pt-0 subheadings colwhite">Cheif Executive officer</div>
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-md col-12 my-3 px-sm-4 px-lg-1 mx-lg-1">
              <img
                src={require("../images/sec6img2.png")}
                alt=""
                className="img-fluid"
              />
              <div className=" pt-4 text-center sect-4-heading-2">Rosana David</div>
              <div className="pt-0 subheadings colwhite">Cheif Executive officer</div>
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-md col-12 my-3 px-sm-4 ps-lg-1 mx-lg-1">
              <img
                src={require("../images/sec6img3.png")}
                alt=""
                className="img-fluid"
              />
              <div className=" text-center pt-4 sect-4-heading-2">Rosana David</div>
              <div className=" pt-0 subheadings colwhite">Cheif Executive officer</div>
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-md col-12 my-3 px-sm-4 ps-lg-1 mx-lg-1">
              <img
                src={require("../images/sec6img4.png")}
                alt=""
                className="img-fluid"
              />
              <div className=" text-center pt-4 sect-4-heading-2">Rosana David</div>
              <div className=" pt-0 subheadings colwhite">Cheif Executive officer</div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default Sect6;
