import React from "react";
import { useState } from "react";
import "../styles/main.css";
import Footer from "../components/Footer";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Getintouch from "../components/Getintouch";
import Navb from "../components/Navb";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );

  return (
    <button
      className="accord-btn my-2"
      type="button"
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

const CustSoftDev = () => {
  const [collapse, setCollapse] = useState(1);

  function handleClick(e) {
    if (e.target.className === "button1") {
      setCollapse(1);
    } else if (e.target.className === "button2") {
      setCollapse(2);
    } else if (e.target.className === "button3") {
      setCollapse(3);
    } else if (e.target.className === "button4") {
      setCollapse(4);
    } else if (e.target.className === "button5") {
      setCollapse(5);
    } else if (e.target.className === "button6") {
      setCollapse(6);
    } else if (e.target.className === "button7") {
      setCollapse(7);
    }
  }

  const [pop, setPop] = useState(false);

  function formPop() {
    setPop(true);
  }

  return (
    <>
      <Navb />
      {pop && <Getintouch setPop={setPop} />}
      {/* Section1 of api page */}
      <div className="container-fluid">
        <div className="col-12">
          <div className="row">
            <div className="col-lg pt-5 pb-5 ps-5 d-flex flex-column">
              <h1 className="sect-1-heading">Custom software</h1>
              <h1 className="sect-1-heading">Development Services</h1>
              <h1 className="sect-1-heading">are trusted by more</h1>
              <h1 className="sect-1-heading">than 1000 Fortune</h1>
              <h1 className="sect-1-heading">companies</h1>
              <p className="subheadings sub-1-width">
                Reimagine your business into a software-first business with our
                classified design and development intent and approach.
              </p>
              <button
                onClick={formPop}
                className="sect-1-button d-flex justify-content-center align-items-center border-0"
              >
                Get Free Consultant
              </button>
            </div>
            <div className="col-lg d-flex justify-content-center mt-5">
              <img
                src={require("../images/custsoftdev.png")}
                alt=""
                className="img-fluid w-75"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Section2 of api page */}
      <div className="container-fluid bgfootcol mt-5 mb-4">
        <div className="col-lg-12">
          <div className="row justify-content-center">
            <div className="col-lg">
              <img
                src={require("../images/egimg1.png")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="col-lg d-flex flex-column justify-content-center align-items-lg-start align-items-center me-lg-5">
              <div className="sect-5-heading pt-3 text-lg-start text-center whitecolor">
                Why you must choose custom software?
              </div>

              <div className="subheadings f17 pt-3 text-lg-start text-center pb-2 colwhite">
                Readymade software can be a good solution but for a short term
                basis. What is the problem? This software cant scaleup with your
                growing business needs. You need a tailor-made software that
                suites and can be enhanced as per your business workflows and
                processes to compete with other businesses.
              </div>
              <div className="sect-1-button d-flex justify-content-center align-items-center my-4">
                Get Started
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section3 of api page */}

      {/* <div className="container">
        <div className="col-lg-12 py-5 px-4 d-flex flex-column align-items-center">
          <div className="api-sec-3-heading text-center pb-3">
            Trusted By 100+ Happy Clients Including These Fortune Companies
          </div>
          <div className="row shadow-lg my-4">
            <div class="d-flex flex-column justify-content-Center align-items-center  col-md col-12 my-3 px-sm-4 pe-lg-1 mx-lg-1">
              <img
                src={require("../images/apisect3img1.png")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-md col-12 my-3 px-sm-4 px-lg-1 mx-lg-1">
              <img
                src={require("../images/apisect3img2.png")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-md col-12 my-3 px-sm-4 ps-lg-1 mx-lg-1">
              <img
                src={require("../images/apisect3img3.jpg")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-md col-12 my-3 px-sm-4 ps-lg-1 mx-lg-1">
              <img
                src={require("../images/apisect3img4.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* Section4 of Api */}

      <div className="container">
        <div className="col-lg-12 px-4 d-flex flex-column align-items-center ">
          <div className="api-sec-3-heading pt-3 d-flex flex-column ">
            Custom Development Offerings:
          </div>
          <div className="row pt-4">
            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-3"></div>
              <div className="sect-2-heading-2  py-3">
                System Integration Services
              </div>
              <div className="subheadings text-center">
                we offer Software integration and customisation services through
                or technical partner “Modern Platforms
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-4"></div>
              <div className=" sect-2-heading-2  py-3">
                Dashboards, ETL, and BI Services
              </div>
              <div className="subheadings text-center">
                Make your business efficient by giving right information and
                letting the systems talk to each other.
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-5"></div>
              <div className="sect-2-heading-2  text-center py-3">
                Legacy Modernization & Application Migration
              </div>
              <div className="subheadings text-center">
                keep your IT up to date with our technical architecture reviews,
                application refinement, and cloud migration services.
              </div>
            </div>
          </div>
          <div className="row pt-lg-4">
            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-6"></div>
              <div className="sect-2-heading-2  py-3">
                Big Data Application Services
              </div>
              <div className="subheadings text-center">
                Using the results of high volume big data process we provide you
                with the deepest insights of your business.
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-7"></div>
              <div className="sect-2-heading-2  py-3">
                Real-time Applications Development Services
              </div>
              <div className="subheadings text-center">
                we provide Real-time data response using stream processing and
                sensor data monitoring.
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-8"></div>
              <div className="sect-2-heading-2  py-3">
                {" "}
                Application Development Services
              </div>
              <div className="subheadings text-center">
                we complement your business goals and needs through the custom
                software with optimal business performance.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section5 of Api */}
      <div className="container-fluid d-flex flex-column align-items-center bg-danger mt-5 position-relative height-sect-5">
        <div className="sect-4-heading text-lg-start text-center">
          Benefits Of Working With KPN
        </div>
        <div className="container px-5 col-lg-12 pb-5 d-lg-flex flex-lg-column align-items-lg-center pt-5">
          <div className="row">
            <div className="col-lg d-flex flex-lg-row flex-column align-items-lg-start align-items-center bg-sec5-1 m5  py-5 px-5 ">
              <img
                src={require("../images/sect2img01.png")}
                alt=""
                className="img-fluid me-lg-3"
              />
              <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
                <div className="api-sec-5-heading text-lg-start text-center">
                  Construct smart applications for your business
                </div>

                <div className="subheadings f17 mt-2 text-lg-start text-center">
                  KPN offers AI-based software development that applies smart
                  solutions for all problems. Our AI-based services can help you
                  find AI opportunities and remove barriers in the system.
                  Together we can open new AI opportunities that can change the
                  future of your business in a real way.
                </div>
              </div>
            </div>

            <div className="col-lg d-flex flex-lg-row flex-column align-items-lg-start align-items-center bg-sec5-1 m5  py-5 px-5">
              <img
                src={require("../images/sect2img02.png")}
                alt=""
                className="img-fluid me-lg-3"
              />

              <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
                <div className="api-sec-5-heading text-lg-start text-center">
                  Use-Case Model
                </div>

                <div className="subheadings f17 mt-2   text-lg-start text-center">
                  This model is ideal for establishing AI projects to run the
                  performance of existing business activities. Our machine
                  learning (ML) engineers assess each use case and make
                  best-customised solutions, focused on your business goals.
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-lg-5">
            <div className="col-lg d-flex flex-lg-row flex-column align-items-lg-start align-items-center bg-sec5-1 m5 py-5 px-5">
              <img
                src={require("../images/sect2img03.png")}
                alt=""
                className="img-fluid me-lg-3"
              />
              <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
                <div className="api-sec-5-heading text-lg-start text-center">
                  Use the best framework and toolsExplore seamless mobility
                </div>

                <div className="subheadings f17 mt-2  text-lg-start text-center">
                  We offer you the freedom to use the framework and tools of
                  your choice without lock-in. It helps you Develop models
                  speedily using automated machine learning techniques. This
                  also offers increased productivity, faster decision making,
                  anomaly detection, and business process automation.
                </div>
              </div>
            </div>

            <div className="col-lg d-flex flex-lg-row flex-column align-items-lg-start align-items-center bg-sec5-1 m5  py-5 px-5">
              <img
                src={require("../images/sect2img04.png")}
                alt=""
                className="img-fluid me-lg-3"
              />

              <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
                <div className="api-sec-5-heading text-lg-start text-center">
                  Quick Proof-of-Concept (POC)
                </div>

                <div className="subheadings f17 mt-2  text-lg-start text-center">
                  Offer service that review your complete business data. Often
                  this activity gets skipped by many vendors and they directly
                  go for building the ML models. We believe in Building a
                  small-scale system and proving the feasibility of the ML
                  models for all your business problem.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section X */}

      <div className="container">
        <div className="col-12">
          <div className="row pt-5">
            <div className="col-lg-6 d-flex flex-column align-items-start">
              <div className="sect-4-heading colblack">
                How Do We Develop Custom Software With Agile:
              </div>
              <img
                src={require("../images/sectionx.jpg")}
                alt=""
                className="img-fluid me-lg-3"
              />
            </div>

            <div className="d-flex flex-column align-items-center col-lg pt-lg-4 mt-lg-5">
              <div class="d-flex flex-column justify-content-center align-items-center my-3  mx-auto px-sm-4 mx-lg-1 sec-2-cards">
                <div className="sect-2-img-2"></div>
                <div className="sect-2-heading-2  py-3">
                  Stand-ups & weekly demos
                </div>
                <div className="subheadings text-center">
                  These activities make sure that everyone is on the same page
                  and allows them to raise their doubts and concerns.
                </div>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center my-3  mx-auto px-sm-4 mx-lg-1 sec-2-cards">
                <div className="sect-2-img-5"></div>
                <div className="sect-2-heading-2  py-3">Tech architecture</div>
                <div className="subheadings text-center">
                  Our expert team break intractable apps into microservices.
                  Dissociating the code allows our team to move faster and
                  independently.
                </div>
              </div>
            </div>

            <div className="d-flex flex-column align-items-center col-lg">
              <div class="d-flex flex-column justify-content-center align-items-center my-3  mx-auto px-sm-4 mx-lg-1 sec-2-cards">
                <div className="sect-2-img-6"></div>
                <div className="sect-2-heading-2  py-3">Code reviews</div>
                <div className="subheadings text-center">
                  Code reviews prior release help us find out issues like file
                  leak, memory leaks, and performance signs.
                </div>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center my-3  mx-auto px-sm-4 mx-lg-1 sec-2-cards">
                <div className="sect-2-img-7"></div>
                <div className="sect-2-heading-2  py-3">Sprint planning</div>
                <div className="subheadings text-center">
                  Itis a collaborative effort put by team members to clarify
                  details, ensure clarity, and understanding.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section6 of Api */}

      <div className="container mt-5">
        <div className="api-sec-6-heading pb-2 pt-3 d-flex justify-content-center">
          Industries We Serve
        </div>
        <div className="subheadings colblack text-center pb-5">
          Industries that we serve include Finance, Software, Consumer,
          Education, Retail, and Manufacturing, etc.
        </div>

        <div className="row">
          <div className="col-lg-12 d-flex sect-6-btn-container">
            <div className="">
              <button
                onClick={handleClick}
                className="button1"
                id="sect-6-api-btn"
              >
                Finance
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button2"
                id="sect-6-api-btn"
              >
                Software
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button3"
                id="sect-6-api-btn"
              >
                Consumer
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button4"
                id="sect-6-api-btn"
              >
                Education
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button5"
                id="sect-6-api-btn"
              >
                Retail
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button6"
                id="sect-6-api-btn"
              >
                Manufacturing
              </button>
            </div>
          </div>
          <hr />

          <div className="row">
            {collapse === 1 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4">
                  Bank analytics web platform
                </div>

                <div className="subheadings pt-2 w479 fs18">
                  For Wealth Managers to mine valuable customer data, take
                  actions in real-time and redefine the customer relationships
                  with Bank Analytics Web App
                </div>
              </div>
            )}
            {collapse === 2 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4">
                  Video interview platform
                </div>

                <div className="subheadings pt-2 w479 fs18">
                  An AI-powered assessment & video platform that leverages video
                  interview, online examinations, coding platform & VM
                  simulations to screen and hire candidates.
                </div>
              </div>
            )}
            {collapse === 3 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4">
                  Web-based communication platform
                </div>

                <div className="subheadings pt-2 w479 fs18">
                  Scalable API-driven web-based instant messaging & video
                  conference platform with real-time data communication built
                  over serverless technology.
                </div>
              </div>
            )}
            {collapse === 4 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4">
                  Educational content management portal
                </div>

                <div className="subheadings pt-2 w479 fs18">
                  Online Content Management Portal for the university to keep a
                  shared repository of text-based learning materials, video
                  content and exam coordination.
                </div>
              </div>
            )}

            {collapse === 5 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4">
                  Web-based e-commerce store
                </div>

                <div className="subheadings pt-2 w479 fs18">
                  A dedicated e-Commerce Web App with product catalogues,
                  advanced search results, online payment and integrated loyalty
                  programs to reach more customers.
                </div>
              </div>
            )}

            {collapse === 6 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4">
                  Inventory management portal
                </div>

                <div className="subheadings pt-2 w479 fs18">
                  Web portal for supply chain management for multiple locations,
                  safety stock, re-order points, cycle counts, demand and
                  distribution requirements planning.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Section7 of Api */}

      {/* <div class="container mt-5 pt-5">
        <div className="api-sec-6-heading pb-3 pt-4 d-flex justify-content-center">
          Our API Solutions
        </div>

        <p className="subheadings text-center mb-5 fs18">
          Integrate & Develop bespoke API with KPN Solutions
        </p>
        <div class="row">
          <div class="col-sm-4">
            <div class="card card-flip h-100 rounded-4">
              <div class="card-front text-white colred rounded-4">
                <div class="card-body  d-flex flex-column align-items-center my-5">
                  <i class="fa fa-search fa-5x float-right"></i>
                  <h3 class="card-title">Payment Gateways</h3>
                  <p class="card-text">Paypal</p>
                  <p class="card-text">Authorize.net</p>
                  <p class="card-text">2Checkout</p>
                  <p class="card-text">BillDesk</p>
                  <p class="card-text">PayU</p>
                  <p class="card-text">EBS</p>
                  <p class="card-text">Sage Pay</p>
                </div>
              </div>
              <div class="card-back bg-white">
                <div class="card-body">
                  <h3 class="card-title">Back</h3>
                  <p class="card-text">
                    Suprise this one has more more more more content on the
                    back!
                  </p>
                  <a href="#" class="btn btn-outline-secondary">
                    Action
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card card-flip h-100 rounded-4">
              <div class="card-front text-white colred rounded-4">
                <div class="card-body  d-flex flex-column align-items-center my-5">
                  <i class="fa fa-search fa-5x float-right"></i>
                  <h3 class="card-title">Payment Gateways</h3>
                  <p class="card-text">Paypal</p>
                  <p class="card-text">Authorize.net</p>
                  <p class="card-text">2Checkout</p>
                  <p class="card-text">BillDesk</p>
                  <p class="card-text">PayU</p>
                  <p class="card-text">EBS</p>
                  <p class="card-text">Sage Pay</p>
                </div>
              </div>
              <div class="card-back bg-dark text-white">
                <div class="card-body">
                  <h3 class="card-title">Back #2</h3>
                  <p class="card-text">
                    Suprise this one has content on the back!
                  </p>
                  <a href="#" class="btn btn-outline-secondary">
                    Action
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card card-flip h-100 rounded-4">
              <div class="card-front text-white colred rounded-4">
                <div class="card-body  d-flex flex-column align-items-center my-5">
                  <i class="fa fa-search fa-5x float-right"></i>
                  <h3 class="card-title">Payment Gateways</h3>
                  <p class="card-text">Paypal</p>
                  <p class="card-text">Authorize.net</p>
                  <p class="card-text">2Checkout</p>
                  <p class="card-text">BillDesk</p>
                  <p class="card-text">PayU</p>
                  <p class="card-text">EBS</p>
                  <p class="card-text">Sage Pay</p>
                </div>
              </div>
              <div class="card-back bg-white">
                <div class="card-body text-primary">
                  <h3 class="card-title">Wow! #3</h3>
                  <p class="card-text">
                    Suprise this one has content on the back!
                  </p>
                  <a href="#" class="btn btn-outline-primary">
                    Action
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Section8 of Api */}

      <div className="container mt-2">
        <div className="api-sec-6-heading pb-5 pt-5 d-flex justify-content-center">
          Frequently Asked Question (FAQ)
        </div>
        <div className="col-lg-12">
          <div className="row justify-content-center">
            <div className="col-lg-5 width-fit-con">
              <Accordion className="shadow">
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="0">
                      What is custom application development? What are custom
                      applications?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="w604">
                      Development of software for specific needs of a business
                      process or group of users is custom application
                      development. The goals of a business by providing
                      workflows are served by such applications which are not
                      generally found in more traditional and widespread
                      off-the-shelf software.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="1">
                      What types of web apps you can develop?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body className="w604">
                      Development of software for specific needs of a business
                      process or group of users is custom application
                      development. The goals of a business by providing
                      workflows are served by such applications which are not
                      generally found in more traditional and widespread
                      off-the-shelf software.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="2">How do PWA works?</CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body className="w604">
                      PWA abbreviated for progressive web apps. It is a new
                      software development methodology used to develop a hybrid
                      of regular web pages and a mobile app. The main aim of
                      PWAs is to fill the gap between native apps and web apps
                      by translocating the benefits of the native apps to the
                      mobile browser.it can be located on the search engines and
                      can be accessed promptly using a browser itself.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="3">
                    How do you choose the right tech architecture?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body className="w604">
                    Following the concept of evolutionary Architectures, we picture your project as a set of different modules.so that each module can have a best suited proper architecture.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="4">
                    How is KPN different from other online talent marketplaces, local agencies and software consulting companies?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body className="w604">
                    Many platforms do not provide delivery and result guarantee. Collaboration and transparency are also lacking in them. KPN started with the goal of assurance, certainty and transparency in software development services.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="5">
                    How well will the team understand my business domain, product and vision?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body className="w604">
                    Not only for success, but we also look for failures. The main aim is to build a global language and shared understanding between team members developers, stakeholders and customers.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="6">
                    Have more questions?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="6">
                    <Card.Body className="w604">
                    Chat with our experts and will get in touch with you ASAP.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
            <div className="col-lg-5 d-flex flex-column align-items-center justify-content-center mt-5 pb-lg-5 mb-lg-5">
              <h1 className="sect-5-heading text-center">
                Have more questions?
              </h1>
              <p className="subheadings text-center w194">
                Let us know and our experts will get in touch with you ASAP.
              </p>

              <div className="sect-1-button d-flex justify-content-center align-items-center">
                Contact Us
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section9 of Api */}
      <div className="py-5 mt-4 mb-3">
        <h1 className="sect-5-heading mx-4 text-center mt-5 mb-4">
          KPN Guarantee
        </h1>
        <p className=" subheadings text-center mx-5">
          We know that if the client’s project launches smoothly, they’ll come
          back for more.We’re willing to over-invest in guaranteeing results,
          rather than under-invest to make our financial reports look pretty in
          the short-run.
        </p>

        <p className="subheadings text-center mx-5 pt-2">
          We offer a risk-free trial period of up to two weeks. You will only
          have to pay if you are happy with the developer and wish to continue.
          If you are unsatisfied, we’ll refund payment or fix issues on our
          dime.
        </p>
      </div>

      {/* Section10 of Api */}

      <div className="container bg-2-sect-4 mb-5">
        <div className=" col-lg-12 pt-3 pb-4">
          <div className="row align-items-center justify-content-center">
            <div className="d-lg-flex flex-column pt-4 pb-1 text-center text-lg-start col-lg-6">
              <h1 className="sect-4-heading-3">
                {" "}
                Hire the best developers and designers around!
              </h1>
              <p className="subheadings f22">We Deliver the best</p>
            </div>

            <div className="dcontents col-lg-6 pt-lg-0 pt-4">
              <div className="subheadings shadow w240 pt-2 mt-4 mb-sm-3 mb-lg-0 text-center sect-4-btn">
                HIRE TOP DEVELOPERS
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CustSoftDev;
