import React from "react";
import "../fonts/Poppins/Poppins-Black.ttf";
import "../styles/main.css";

function Footer() {
  return (
    <>
      <section class=" bgfootcol">
        <footer class="footcolor text-white pb-5">
          <div class="container mx-lg-5 pb-5 pt-5">
            <div class="row d-lg-flex justify-content-lg-center pt-3">
              <div class="col-lg-6 col-md-12 mb-4 mb-md-0 ps-lg-5  pe-lg-0">
                <h5 class="footer-heading">About Us</h5>

                <p className="pt-3 subheadings colwhite subheading-width-footer">
                  We’re a tech company with a mission to help
                  successful companies extend their tech capacity. We have
                  helped organizations ranging from Startups that went public,
                  to Fortune 500 companies,..Learn more:-
                </p>
              </div>

              <div class="col-lg-2 col-md-6 mb-4 mb-md-0 pe-lg-4 ps-lg-5 mt-3 mt-lg-0">
                <h5 class=" mb-3 footer-heading w110">Quick Links</h5>

                <div class="foot">
                  <div className="subheadings colwhite w110">Home</div>
                  <div className="subheadings colwhite w110">About Us</div>
                  <div className="subheadings colwhite w110">Services</div>
                  <div className="subheadings colwhite w110">Blog</div>
                  <div className="subheadings colwhite w110">Contact</div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 mb-4 mb-md-0 ps-lg-0 mt-3 mt-lg-0">
                <h5 class=" mb-3 footer-heading">US Info</h5>

                <div class="foot2">
                  <div className="subheadings colwhite">Address: 269 W Adelaide </div>
                  <div className="subheadings colwhite">Dr Saint johns</div>
                  <div className="subheadings colwhite">FL 32259</div>
                  <div style={{height: "24px"}}></div>
                  <div className="subheadings colwhite">US number – </div>
                </div>
              </div>

             
            </div>
          </div>
        </footer>
      </section>
    </>
  );
}

export default Footer;
