import React from "react";
import "../fonts/Poppins/Poppins-Black.ttf";
import "../styles/main.css";

function Sect4() {
  return (
    <>
        
        <div className="container-fluid d-flex flex-column align-items-center bg-sect-4 position-relative height-sect-4">
          <div className="sect-4-heading f28 text-lg-start text-center">
          Why Choose KPN As The
Right Technology Partner?
          </div>
        <div className=" col-lg-12 pb-5 d-lg-flex flex-lg-column align-items-lg-center pt-5 px-5">
          <div className="row">

          <div className="col-lg d-flex flex-lg-row flex-column align-items-center justify-content-lg-center pt-lg-0 pt-4">
              <img
                src={require("../images/sect4img01.png")}
                alt=""
                className="img-fluid me-lg-3 "
              />
              <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
              <div className="sect-4-heading-2 text-lg-start text-center">
              Experience Design Thinking
              </div>

              <div className="subheadings mt-2 sect-4-subheadcol widthsub1 text-lg-start text-center">
              Our designers are adapted to a human-centered interactive process to understand client product pre-requisite and understand the experience.
              </div>
            </div>
            </div>

            

            <div className="col-lg d-flex flex-lg-row flex-column align-items-center justify-content-lg-center pt-lg-0 pt-5">
              <img
                src={require("../images/sect4img02.png")}
                alt=""
                className="img-fluid me-lg-3"
              />

<div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
              <div className="sect-4-heading-2 text-lg-start text-center">
              Partner Approach
              </div>

              <div className="subheadings mt-2  sect-4-subheadcol text-lg-start text-center">
              Embodies ideas-driven to customer satisfaction and not as just a service provider but a technology partner
              </div>
            </div>
            </div>

           

            
          </div>

          <div className="row pt-lg-5">

          <div className="col-lg d-flex flex-lg-row flex-column align-items-center justify-content-lg-center pt-lg-0 pt-5">
              <img
                src={require("../images/sect4img03.png")}
                alt=""
                className="img-fluid me-lg-3"
              />
              <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
              <div className="sect-4-heading-2 text-lg-start text-center">
              Product Mindset
              </div>

              <div className="subheadings mt-2 sect-4-subheadcol text-lg-start text-center">
              We are product managers that challenge the digital economy the way our customer needs to break-even the market
              </div>
            </div>
            </div>

            

            <div className="col-lg d-flex flex-lg-row flex-column align-items-center justify-content-lg-center pt-lg-0 pt-5">
              <img
                src={require("../images/sect4img04.png")}
                alt=""
                className="img-fluid me-lg-3"
              />

<div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
              <div className="sect-4-heading-2 text-lg-start text-center">
              Startup Philosophy
              </div>

              <div className="subheadings mt-2 sect-4-subheadcol text-lg-start text-center">
              Adhere to a scientific approach to creating and managing nimble, proactive, and agile startup projects.
              </div>
            </div>
            </div>

           

            
          </div>
        </div>
        <div className="container bg-2-sect-4 position-absolute bottom138">
        <div className=" col-lg-12 pt-3 pb-4">
          <div className="row align-items-center justify-content-center">
            <div className="d-lg-flex justify-content-center pt-4 pb-1 text-center text-lg-start align-items-center sect-4-heading-3 col-lg-6">
              We Create Digital Products That Make
People Live Easier.
              </div>
    

            <div className="dcontents col-lg-6 pt-lg-0 pt-4">
              <div className="subheadings pt-2 mt-4 mb-sm-3 mb-lg-0 text-center sect-4-btn">
                Get Started
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>


      
    </>
  );
}

export default Sect4;
