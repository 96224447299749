import React from "react";
import "../fonts/Poppins/Poppins-Black.ttf";
import "../styles/main.css";

function Sect1() {
  return (
    <>
      <div className="container-fluid banner-sect-1 pt-5 pb-5 ps-5 d-flex flex-column">
        <h1 className="gradfont pt-5">Bringing Thoughts to Brands</h1>
        <h1 className="sect-1-heading">Your Digital</h1>
        <h1 className="sect-1-heading"> Transformation Partner</h1>
        <h1 className="sect-1-heading">for Software & App Solution</h1>
        <p className="subheadings  sub-1-width">
          Transcend your business to the next level with custom software
          development services. We identify gaps, build opportunities, craft
          solutions, and disrupt innovation to speed up business performance.
        </p>
        <div className="sect-1-button d-flex justify-content-center align-items-center">
          Get Started
        </div>
      </div>
    </>
  );
}

export default Sect1;
