import React from "react";
import { useState } from "react";
import "../styles/main.css";
import Footer from "../components/Footer";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Getintouch from "../components/Getintouch";
import Navb from "../components/Navb";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );

  return (
    <button
      className="accord-btn my-2"
      type="button"
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

const Api = () => {
  const [collapse, setCollapse] = useState(1);

  function handleClick(e) {
    if (e.target.className === "button1") {
      setCollapse(1);
    } else if (e.target.className === "button2") {
      setCollapse(2);
    } else if (e.target.className === "button3") {
      setCollapse(3);
    } else if (e.target.className === "button4") {
      setCollapse(4);
    } else if (e.target.className === "button5") {
      setCollapse(5);
    } else if (e.target.className === "button6") {
      setCollapse(6);
    }
  }

  const [pop, setPop] = useState(false);

  function formPop() {
    setPop(true);
  }

  return (
    <>
      <Navb />
      {pop && <Getintouch setPop={setPop} />}
      {/* Section1 of api page */}
      <div className="container-fluid banner-sect-1 pt-5 pb-5 ps-5 d-flex flex-column">
        <h1 className="sect-1-heading">Custom API Integration</h1>
        <h1 className="sect-1-heading">Services for Web and</h1>
        <h1 className="sect-1-heading">App</h1>
        <p className="subheadings  sub-1-width">
          Get quality web and app API Integration Services from best custom
          third-party API development solution.
        </p>
        <button
          onClick={formPop}
          className="sect-1-button d-flex justify-content-center align-items-center border-0"
        >
          Get Started
        </button>
      </div>

      {/* Section2 of api page */}
      <div className="container-fluid bgfootcol mt-5 mb-4">
        <div className="col-lg-12">
          <div className="row justify-content-center">
            <div className="col-lg">
              <img
                src={require("../images/egimg1.png")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="col-lg d-flex flex-column justify-content-center align-items-lg-start align-items-center m ps-lg-4 ms-lg-5">
              <div className="sect-5-heading pt-3 text-lg-start text-center whitecolor">
                Why KPN API Integration Services?
              </div>

              <div className="subheadings f17 pt-3 text-lg-start text-center pb-2 colwhite">
                We provide the best third-party API integration services. Our
                API automation service supports the business to reduce burden
                and cost to integrate existing technology to business-critical
                applications. Seamless Application Programing Interface (API) is
                an ability to connect custom applications with an external
                system with a network or cloud
              </div>
              <div className="sect-1-button d-flex justify-content-center align-items-center my-4">
                Get Started
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section3 of api page */}



      {/* <div className="container">
        <div className="col-lg-12 py-5 px-4 d-flex flex-column align-items-center">
          <div className="api-sec-3-heading text-center pb-3">
            Trusted By 100+ Happy Clients Including These Fortune Companies
          </div>
          <div className="row shadow-lg my-4">
            <div class="d-flex flex-column justify-content-Center align-items-center  col-md col-12 my-3 px-sm-4 pe-lg-1 mx-lg-1">
              <img
                src={require("../images/apisect3img1.png")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-md col-12 my-3 px-sm-4 px-lg-1 mx-lg-1">
              <img
                src={require("../images/apisect3img2.png")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-md col-12 my-3 px-sm-4 ps-lg-1 mx-lg-1">
              <img
                src={require("../images/apisect3img3.jpg")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-md col-12 my-3 px-sm-4 ps-lg-1 mx-lg-1">
              <img
                src={require("../images/apisect3img4.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* Section4 of Api */}

      <div className="container">
        <div className="col-lg-12 px-4 d-flex flex-column align-items-center ">
          <div className="api-sec-3-heading pt-3 ">
            How API Integration Solution Can Help?
          </div>
          <div className="row pt-4">
            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-3"></div>
              <div className="sect-2-heading-2  py-3">Custom API Solutions</div>
              <div className="subheadings text-center">
                KPN custom API solution covers the web and app development,
                publishing, deployment, integration, and maintenance. Our
                dedicated engineers develop robust API architecture suing a
                reliable security protocol.
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-4"></div>
              <div className=" sect-2-heading-2  py-3">
                API Development Platform
              </div>
              <div className="subheadings text-center">
                We offer a leading API-First platform to help businesses
                accelerate full control. Our API management platform supports
                access to data, and service. Our team is dedicated to creating
                APIs for desktop,
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-5"></div>
              <div className="sect-2-heading-2  text-center py-3">
                Custom API Integration
              </div>
              <div className="subheadings text-center">
                Our engineer follows best practice when integration API by
                implementing open-source and third-party APIs. We network
                business systems and process by adding web functional integral
                to web application.
              </div>
            </div>
          </div>
          <div className="row pt-lg-4">
              <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
                <div className="sect-2-img-6"></div>
                <div className="sect-2-heading-2  py-3">
                  API Service Implementation
                </div>
                <div className="subheadings text-center">
                  We follow modern application architecture to configure APIs
                  for web, mobile, console, console, search engines, database,
                  and intranet systems. We implement both internal as well as
                  external.
                </div>
              </div>

              <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
                <div className="sect-2-img-7"></div>
                <div className="sect-2-heading-2  py-3">
                  API Integration as a Service
                </div>
                <div className="subheadings text-center">
                  KPN provides custom AP development and API service
                  integrations to personalise web and mobile applications. We
                  use APIs as a Service (APIaaS) for businesses who want to web
                  services .
                </div>
              </div>

              <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
                <div className="sect-2-img-8"></div>
                <div className="sect-2-heading-2  py-3">
                  {" "}
                  API Testing and Automation
                </div>
                <div className="subheadings text-center">
                  As a part of API integration, we help the business to design
                  and configure the API Integration testing platform to automate
                  functional, validation, UI, security, penetration, runtime and
                  load.
                </div>
              </div>
            </div>
        </div>
      </div>





      {/* Section5 of Api */}
      <div className="container-fluid d-flex flex-column align-items-center bg-danger mt-5 position-relative height-sect-5">
        <div className="sect-4-heading text-lg-start text-center">
        Benefits Of Working With Syntrino
        </div>
        <div className="container px-5 col-lg-12 pb-5 d-lg-flex flex-lg-column align-items-lg-center pt-5">
          <div className="row">
            <div className="col-lg d-flex flex-lg-row flex-column align-items-lg-start align-items-center bg-sec5-1 m5  py-5 px-5 ">
              <img
                src={require("../images/sect2img01.png")}
                alt=""
                className="img-fluid me-lg-3"
              />
              <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
                <div className="api-sec-5-heading text-lg-start text-center">
                  Build smart applications for smarter business
                </div>

                <div className="subheadings f17 mt-2 text-lg-start text-center">
                  KPN provides AI software development to apply{" "}
                  <b>smarter solutions to business problems</b>. Our AI
                  consulting can help you spot AI opportunities and eliminate
                  barriers to AI. Together we can open up new AI opportunities
                  that can – in a very real way – change the future.
                </div>
              </div>
            </div>

            <div className="col-lg d-flex flex-lg-row flex-column align-items-lg-start align-items-center bg-sec5-1 m5  py-5 px-5">
              <img
                src={require("../images/sect2img02.png")}
                alt=""
                className="img-fluid me-lg-3"
              />

              <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
                <div className="api-sec-5-heading text-lg-start text-center">
                  Use the tools and frameworks of your choice
                </div>

                <div className="subheadings f17 mt-2   text-lg-start text-center">
                  Use the tools and frameworks of your choice without lock-in.
                  Developing models faster using automated machine learning. Our
                  machine learning powered business applications{" "}
                  <b>
                    enable faster decision making, increased productivity,
                    business process automation, and faster anomaly detection.
                  </b>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-lg-5">
            <div className="col-lg d-flex flex-lg-row flex-column align-items-lg-start align-items-center bg-sec5-1 m5 py-5 px-5">
              <img
                src={require("../images/sect2img03.png")}
                alt=""
                className="img-fluid me-lg-3"
              />
              <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
                <div className="api-sec-5-heading text-lg-start text-center">
                  Use-Case Model
                </div>

                <div className="subheadings f17 mt-2  text-lg-start text-center">
                  Use-case model is ideal for identifying AI projects to drive
                  the performance of existing activities. Our ML engineers
                  analyze each use case and create highly-customized solutions,
                  aligned with unique business goals.
                </div>
              </div>
            </div>

            <div className="col-lg d-flex flex-lg-row flex-column align-items-lg-start align-items-center bg-sec5-1 m5  py-5 px-5">
              <img
                src={require("../images/sect2img04.png")}
                alt=""
                className="img-fluid me-lg-3"
              />

              <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg pt-lg-0 pt-2">
                <div className="api-sec-5-heading text-lg-start text-center">
                  Quick POC (Proof-of-Concept)
                </div>

                <div className="subheadings f17 mt-2  text-lg-start text-center">
                  We review your business and data well. Often times many
                  vendors miss this part and go directly and try to build ML
                  models. Build a{" "}
                  <b>
                    small-scale system, proving the viability of the machine
                    learning
                  </b>{" "}
                  models for your problem.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section6 of Api */}

      <div className="container mt-5">
        <div className="api-sec-6-heading pb-5 pt-3 d-flex justify-content-center">
          Industries We Serve
        </div>

        <div className="row">
          <div className="col-lg-12 d-flex sect-6-btn-container">
            <div className="">
              <button
                onClick={handleClick}
                className="button1"
                id="sect-6-api-btn"
              >
                Sales
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button2"
                id="sect-6-api-btn"
              >
                Marketing
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button3"
                id="sect-6-api-btn"
              >
                Communication
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button4"
                id="sect-6-api-btn"
              >
                eCommerce
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button5"
                id="sect-6-api-btn"
              >
                Logistics
              </button>
            </div>

            <div className="">
              <button
                onClick={handleClick}
                className="button6"
                id="sect-6-api-btn"
              >
                Social Logins
              </button>
            </div>
          </div>
          <hr />

          <div className="row">
            {collapse === 1 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4">Sales API integration</div>

                <div className="subheadings pt-2 w479 fs18">
                  We are industry experts who understand your special Sales API
                  integration and need custom software to support customers CRM
                  enabled with the database, lead, email, appointments, task,
                  and pipeline management.
                </div>
              </div>
            )}
            {collapse === 2 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4">
                  Marketing & automation
                </div>

                <div className="subheadings pt-2 w479 fs18">
                  Autumn marketing process across industry-leading services.
                  Marketing automation is a new growth engine for business to
                  manage clients, generate business, engage customers, and
                  promote sales.
                </div>
              </div>
            )}
            {collapse === 3 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4">
                  SMS & Payment Gateway API Integration
                </div>

                <div className="subheadings pt-2 w479 fs18">
                  Integrate SMS and Payment API with existing software to
                  communicate with customers. We offer full support with
                  detailed documentation with developers code.
                </div>
              </div>
            )}
            {collapse === 4 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4">
                  Shopping API integration
                </div>

                <div className="subheadings pt-2 w479 fs18">
                  Custom shopping API allows the business to carry smooth
                  interaction directly with the merchant centre platform. Our
                  API integration solution for eCommerce business support
                  business to increase efficiency in the management shopping
                  cart and payment checkout.
                </div>
              </div>
            )}

            {collapse === 5 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4">
                  Shipping API Integration
                </div>

                <div className="subheadings pt-2 w479 fs18">
                  Integrating logistics API enables the business to integrate
                  shipping functionality directly into your website.
                </div>
              </div>
            )}

            {collapse === 6 && (
              <div className="col-lg-12 d-flex flex-column align-items-lg-start">
                <div className="sect-5-heading pt-4">Social Login Gateways</div>

                <div className="subheadings pt-2 w479 fs18">
                  Seamless sign-in for social users to authorize service to log
                  in, manage the account, track analytics, and gain information.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Section7 of Api */}
      <div class="container mt-5 pt-5">
        <div className="api-sec-6-heading pb-3 pt-4 d-flex justify-content-center">
          Our API Solutions
        </div>

        <p className="subheadings text-center mb-5 fs18">
          Integrate & Develop bespoke API with KPN Solutions
        </p>
        <div class="row">
          <div class="col-sm-4">
            <div class="card card-flip h-100 rounded-4">
              <div class="card-front text-white colred rounded-4">
                <div class="card-body  d-flex flex-column align-items-center my-5">
                  <i class="fa fa-search fa-5x float-right"></i>
                  <h3 class="card-title">Payment Gateways</h3>
                  <p class="card-text">Paypal</p>
                  <p class="card-text">Authorize.net</p>
                  <p class="card-text">2Checkout</p>
                  <p class="card-text">BillDesk</p>
                  <p class="card-text">PayU</p>
                  <p class="card-text">EBS</p>
                  <p class="card-text">Sage Pay</p>
                </div>
              </div>
              <div class="card-back bg-white">
                <div class="card-body">
                  <h3 class="card-title">Back</h3>
                  <p class="card-text">
                    Suprise this one has more more more more content on the
                    back!
                  </p>
                  <a href="#" class="btn btn-outline-secondary">
                    Action
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card card-flip h-100 rounded-4">
              <div class="card-front text-white colred rounded-4">
                <div class="card-body  d-flex flex-column align-items-center my-5">
                  <i class="fa fa-search fa-5x float-right"></i>
                  <h3 class="card-title">Payment Gateways</h3>
                  <p class="card-text">Paypal</p>
                  <p class="card-text">Authorize.net</p>
                  <p class="card-text">2Checkout</p>
                  <p class="card-text">BillDesk</p>
                  <p class="card-text">PayU</p>
                  <p class="card-text">EBS</p>
                  <p class="card-text">Sage Pay</p>
                </div>
              </div>
              <div class="card-back bg-dark text-white">
                <div class="card-body">
                  <h3 class="card-title">Back #2</h3>
                  <p class="card-text">
                    Suprise this one has content on the back!
                  </p>
                  <a href="#" class="btn btn-outline-secondary">
                    Action
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card card-flip h-100 rounded-4">
              <div class="card-front text-white colred rounded-4">
                <div class="card-body  d-flex flex-column align-items-center my-5">
                  <i class="fa fa-search fa-5x float-right"></i>
                  <h3 class="card-title">Payment Gateways</h3>
                  <p class="card-text">Paypal</p>
                  <p class="card-text">Authorize.net</p>
                  <p class="card-text">2Checkout</p>
                  <p class="card-text">BillDesk</p>
                  <p class="card-text">PayU</p>
                  <p class="card-text">EBS</p>
                  <p class="card-text">Sage Pay</p>
                </div>
              </div>
              <div class="card-back bg-white">
                <div class="card-body text-primary">
                  <h3 class="card-title">Wow! #3</h3>
                  <p class="card-text">
                    Suprise this one has content on the back!
                  </p>
                  <a href="#" class="btn btn-outline-primary">
                    Action
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section8 of Api */}

      <div className="container mt-2">
        <div className="api-sec-6-heading pb-5 pt-5 d-flex justify-content-center">
          Frequently Asked Question (FAQ)
        </div>
        <div className="col-lg-12">
          <div className="row justify-content-center">
            <div className="col-lg-5 width-fit-con">
              <Accordion className="shadow">
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="0">
                      What is Third-Party API Integration ?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="w604">
                      Third-party API integration is a solution when a business
                      uses a third-party developed API to integration with other
                      business technology services. By integrating the API it
                      allows the portal to gain access to functionalities of
                      third-party API services. Enabling third-party API to the
                      existing system allows business owners to save money &
                      time, ease the use of plugins, and custom-made to tweak
                      code during integration.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="1">
                      Why Choose the Best Third-Party API Integration Company?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body className="w604">
                      Our approach is to combine a deep understanding of user
                      behaviour aligned to meet business goals of our clients.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="2">
                      How to Recognise Third-Party API Integration Solution
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body className="w604">
                      KPN is recognized as a prominent Third-Party API
                      integration company for excellence in delivering quality
                      services. Our expert software engineers are API
                      professionals specializes in web designing, development,
                      and CSS media query.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="3">
                      What is Smart Tech for Resourceful Business ?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body className="w604">
                      We follow strict quality standard so that you
                      best-in-class API integration services. KPN offers
                      comprehensive API integration services to resolve business
                      problems. We abide integration to connect data,
                      applications, and devices using definitive protocols.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="4">
                      How do you use Latest API Technologies ?
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body className="w604">
                      With API being essential for innovative business, we
                      amalgamate complex technologies driven by a set of
                      specific platforms. Thus, making them easily understood
                      Application Programming Interface with a proven process to
                      alleviate overlaps, gaps, and redundancy that exist in any
                      department.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="card-custom">
                    <CustomToggle eventKey="5">
                      Fuel Department Interconnectivity
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body className="w604">
                      Our backend systems fuel stronger integration to enable
                      seamless interconnectivity across diverse platforms.
                      Perhaps, to ensure our perfectly built-in custom API
                      integration match your enterprise system requirement.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
            <div className="col-lg-5 d-flex flex-column align-items-center justify-content-center mt-5 pb-lg-5 mb-lg-5">
              <h1 className="sect-5-heading text-center">
                Have more questions?
              </h1>
              <p className="subheadings text-center w194">
                Let us know and our experts will get in touch with you ASAP.
              </p>

              <div className="sect-1-button d-flex justify-content-center align-items-center">
                Contact Us
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section9 of Api */}
      <div className="py-5 mt-4 mb-3">
        <h1 className="sect-5-heading mx-4 text-center mt-5 mb-4">
          KPN Guarantee
        </h1>
        <p className=" subheadings text-center mx-5">
          We know that if the client’s project launches smoothly, they’ll come
          back for more.We’re willing to over-invest in guaranteeing results,
          rather than under-invest to make our financial reports look pretty in
          the short-run.
        </p>

        <p className="subheadings text-center mx-5 pt-2">
          We offer a risk-free trial period of up to two weeks. You will only
          have to pay if you are happy with the developer and wish to continue.
          If you are unsatisfied, we’ll refund payment or fix issues on our
          dime.
        </p>
      </div>

      {/* Section10 of Api */}

      <div className="container bg-2-sect-4 mb-5">
        <div className=" col-lg-12 pt-3 pb-4">
          <div className="row align-items-center justify-content-center">
            <div className="d-lg-flex flex-column pt-4 pb-1 text-center text-lg-start col-lg-6">
              <h1 className="sect-4-heading-3">
                {" "}
                Hire the best developers and designers around!
              </h1>
              <p className="subheadings f22">We Deliver the best</p>
            </div>

            <div className="dcontents col-lg-6 pt-lg-0 pt-4">
              <div className="subheadings shadow w240 pt-2 mt-4 mb-sm-3 mb-lg-0 text-center sect-4-btn">
                HIRE TOP DEVELOPERS
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Api;
