import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Api from "./pages/Api";
import AppDevServ from "./pages/AppDevServ";
import CustSoftDev from "./pages/CustSoftDev";
import MobAppDev from "./pages/MobAppDev";


function App() {
  return (
  <>
  <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/apintegdev" element={<Api />} />
        <Route path="/appdevserv" element={<AppDevServ />} />
        <Route path="/custsoftdev" element={<CustSoftDev />} />
        <Route path="/mobappdev" element={<MobAppDev />} />
      </Routes>
    </BrowserRouter>
  </>
  );
}

export default App;
