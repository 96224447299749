import React from "react";
import "../styles/main.css";

function Sect2() {
  return (
    <>
      
      <div className="container">
        <div className="col-lg-12 py-5 px-4">
          <div className="row pt-lg-4">
            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-3"></div>
              <div className="sect-2-heading-2  py-3">Hire Dedicated Developer</div>
              <div className="subheadings text-center">
              Hire top software & app developer to your team to support digital transformation
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-4"></div>
              <div className=" sect-2-heading-2  py-3">Web Application Development Services</div>
              <div className="subheadings text-center">
              Build enterprise-grade web apps that power mission-critical workflows.
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-5"></div>
              <div className="sect-2-heading-2  text-center py-3">Custom Software Solution</div>
              <div className="subheadings text-center">
              Share your strategic requirement and our skilled developer will build software.
              </div>
            </div>


          </div>
          <div className="row pt-lg-4">
            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-6"></div>
              <div className="sect-2-heading-2  py-3">Software Product Engineering</div>
              <div className="subheadings text-center">
              We are a software craftsman developing scalable products your users will love.
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-7"></div>
              <div className="sect-2-heading-2  py-3">Digital Transformation</div>
              <div className="subheadings text-center">
              We empower businesses with disruptive digital technology leading them to a way of doing things.
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center col-lg col-12 my-3 mx-auto px-sm-4 mx-lg-1 sec-2-cards">
              <div className="sect-2-img-8"></div>
              <div className="sect-2-heading-2  py-3">AI/ML Development Services</div>
              <div className="subheadings text-center">
              We use the full potential of innovative AI & ML solution across industry.
              </div>
            </div>
            </div>
        </div>
      </div>

    </>
  );
}

export default Sect2;
